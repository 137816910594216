import React, { useEffect } from "react";
import { MantineProvider, Loader } from "@mantine/core";
import "@mantine/core/styles.css";
import { jwtDecode } from "jwt-decode";
import { I18nextProvider, useTranslation } from "react-i18next";
import { useAppDispatch } from "./store/hooks";
import { setUser } from "./store/userSlice";
import theme from "./constants/theme";
import { getConfig } from "./utils/conversions";
import axios from "./axios/axiosConfig";
import AppRoutes from "./routes/AppRoutes";
import { useContext } from "react";
import { KeycloakContext } from "./Keycloak/KeycloakProvider";

const App: React.FC = () => {
	const { i18n } = useTranslation();
	const dispatch = useAppDispatch();
	const { keycloakValue, authenticated, loading } = useContext(KeycloakContext);
	const fundQueryConfig = getConfig("fund_query");
	const isFundQueryOption1 = +(fundQueryConfig?.DISPLAY_OPTION?.value || 1) === 1;

	useEffect(() => {
		const language = sessionStorage.getItem("lang");
		if (language) i18n.changeLanguage(language);
	}, []);

	useEffect(() => {
		if (authenticated && keycloakValue?.token) {
			try {
				const decodedToken: any = jwtDecode(keycloakValue.token);
				if (decodedToken && !keycloakValue.isTokenExpired()) {
					axios.defaults.headers.common.Authorization = `Bearer ${keycloakValue.token}`;
					console.log("Token set in axios headers");
				}

				dispatch(
					setUser({
						username: decodedToken?.preferred_username,
						givenName: decodedToken?.given_name,
						fullName: decodedToken?.name,
						roles: decodedToken?.permissions[0].role,
					}),
				);
			} catch (error) {
				console.error("Error decoding token:", error);
				delete axios.defaults.headers.common.Authorization;
			}
		}
	}, [authenticated, keycloakValue, dispatch]);

	useEffect(() => {
		return () => {
			delete axios.defaults.headers.common.Authorization;
		};
	}, []);

	if (loading || !authenticated)
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "100vh",
				}}
			>
				<Loader size={65} color='blue' />
			</div>
		);

	return (
		<I18nextProvider i18n={i18n}>
			<AppRoutes
				decodedToken={keycloakValue?.token ? jwtDecode(keycloakValue.token) : null}
				isFundQueryOption1={isFundQueryOption1}
			/>
		</I18nextProvider>
	);
};

export default App;
