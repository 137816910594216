import React, { useState, useEffect } from "react";
import { Flex, Grid } from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import SearchSection from "../AllFundQuery/PresetsOptions2/SearchInput";
import { t } from "i18next";
import styles from "./AIAssistant.module.css";
import clsx from "clsx";
import { BaseLayoutProps } from "../../types";
import { AIChatNavControls } from "./AIChatNavControls";
import { AIChatHistory } from "./AIChatHistory";
import { useNavigate } from "react-router-dom";
import { NewCategorizedChats } from "../../store/features/chat/types";
import { useAppDispatch } from "../../store/hooks";
import { clearChatList } from "../../store/features/chat/newChatSlice";

interface AIChatNavBarProps extends BaseLayoutProps {
	categorizedChats: NewCategorizedChats;
	onNewChat: () => void;
	onLoadChat: (sessionId: string) => void;
	isLeftNavOpen: boolean;
	selectedItem: string;
	setSelectedItem: (item: string) => void;
	toggleSidebar: () => void;
}

export const AIChatNavBar: React.FC<AIChatNavBarProps> = ({
	isLeftNavOpen,
	toggleSidebar,
	sidebarClassName = "preset-leftNav",
	categorizedChats,
	onNewChat,
	onLoadChat,
	selectedItem,
	setSelectedItem,
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const dispatch = useAppDispatch();
	const urlSymbol = window.location.pathname.split("/").pop();
	const navigate = useNavigate();

	useEffect(() => {
		if (urlSymbol) {
			const matchingChat = [
				...categorizedChats.today,
				...categorizedChats.yesterday,
				...categorizedChats.last7Days,
			].find((chat) => chat.metadata?.ticker_symbol === urlSymbol);

			if (matchingChat) {
				setSelectedItem(matchingChat.chat_id);
			}
		}
	}, [urlSymbol, categorizedChats, setSelectedItem]);

	const handleItemClick = async (itemLabel: string, chatId?: string) => {
		const newSelection = chatId || itemLabel.toLowerCase();
		setSelectedItem(newSelection);

		if (chatId) {
			try {
				const chat =
					categorizedChats.today.find((c) => c.chat_id === chatId) ||
					categorizedChats.yesterday.find((c) => c.chat_id === chatId) ||
					categorizedChats.last7Days.find((c) => c.chat_id === chatId);

				if (chat?.metadata?.ticker_symbol) {
					await onLoadChat(chatId);

					navigate(`/ai-assistant/${chat.metadata.ticker_symbol}`, { replace: true });
				}
			} catch (error) {
				console.error("Error loading chat session:", error);
			}
		} else {
			if (itemLabel.toLowerCase() === "new chat") {
				onNewChat();
			}
		}
	};

	useEffect(() => {
		return () => {
			dispatch(clearChatList());
		};
	}, [dispatch]);

	const sidebarClasses = clsx(
		styles.sidebar,
		isLeftNavOpen ? styles["sidebar--open"] : styles["sidebar--closed"],
	);

	const contentClasses = clsx(
		styles.sidebarContent,
		sidebarClassName,
		isLeftNavOpen ? styles["sidebarContent--visible"] : styles["sidebarContent--hidden"],
	);

	return (
		<Flex className={styles.container}>
			<div className={sidebarClasses}>
				<div className={contentClasses}>
					<Grid className={styles.gridContainer}>
						<Grid.Col className={styles.searchSection}>
							<Flex justify='space-between' align='center'>
								<SearchSection
									totalGrid={12}
									centerGrid={9}
									rightGrid={1.5}
									searchQuery={searchQuery}
									placeholder={`${t("SEARCH")} ${t("PRESETS")}`}
									onChange={setSearchQuery}
								/>
							</Flex>
						</Grid.Col>

						<Grid.Col className={styles.navigationSection}>
							<AIChatNavControls selectedItem={selectedItem} onNavItemClick={handleItemClick} />
						</Grid.Col>

						<Grid.Col>
							<AIChatHistory
								categorizedChats={categorizedChats}
								selectedItem={selectedItem}
								onChatSelect={handleItemClick}
								searchQuery={searchQuery}
							/>
						</Grid.Col>
					</Grid>
				</div>

				<button
					onClick={toggleSidebar}
					className={styles.toggleButton}
					aria-label={isLeftNavOpen ? "Close sidebar" : "Open sidebar"}
				>
					{isLeftNavOpen ? <IconChevronLeft size={24} /> : <IconChevronRight size={24} />}
				</button>
			</div>
		</Flex>
	);
};
