import React, { useEffect, useRef, useState } from "react";
import { ThresoldLeft } from "../../components/Algojournal/ThresoldLeft";
import { rowInfo } from "../../types";
import { ThresoldRight } from "../../components/Algojournal/ThresoldRight";
import { OrderTable } from "../../components/Algojournal/OrderTable";
import axios from "axios";
import { useMantineTheme, Text, Flex, Box } from "@mantine/core";
import { services } from "../../services";
import { useParams } from "react-router-dom";
import { KeyValuePairs } from "../../types";
import ErrorMessage from "../../components/Common/ErrorMessage";
import IconWrapper from "../../components/Common/IconWrapper";
import { t } from "i18next";
import { getConfig } from "../../utils/conversions";
import SplitPane, { Pane } from "split-pane-react";
import "split-pane-react/esm/themes/default.css";
import "./AnalysisOrderTab.css";
import { ISplitProps } from "split-pane-react/esm/types";
import { getBadgeStyles } from "../../utils/getStyles";
import { LoaderIcon } from "../../components/Common/LoaderIcon";

import { useDispatch, useSelector } from "react-redux";
import { fetchOrderData } from "../../store/orderSlice";
import { RootState, AppDispatch } from "../../store/store";
import { ConstantUtils } from "../../utils/constantUtils";

interface AnalysisOrderTabProps {
	fundStats: {
		fund_id: string;
		total_nav: number;
	};
}

const AnalysisOrderTab: React.FC<AnalysisOrderTabProps> = ({ fundStats }) => {
	const [algoJournalData, setAlgoJournalData] = useState<any>(null);
	const dispatch: AppDispatch = useDispatch();
	const { data: orderData, loading: orderLoading, error: orderError, summary } = useSelector(
		(state: RootState) => state.orders
	  );
	
	
	const [orderListId, setOrderListId] = useState<string[]>([]);
	const splitPaneRef = useRef<HTMLDivElement>(null);
	const theme = useMantineTheme();
	const { fund_id, transaction_id, target_mode, target_date, fund_state, calc_date } = useParams();
	const [errorMessage, setErrorMessage] = useState<string | null>();
	const [errorMessageOrder, setErrorMessageOrder] = useState<string | number | null>();
	const formattedTargetMode = target_mode ? target_mode.toUpperCase().replace(/-/g, "_") : null;
	const [loading, setLoading] = useState<boolean>(true);
	const config = getConfig("order");
	const option: number = +(config?.DISPLAY_OPTION?.value || 1);
	const isOption1 = option === 1;
	interface displayConfigProps extends Partial<ISplitProps> {
		minSize: number | undefined;
		maxSize: string;
		defaultSizes: any;
	}

	const displayConfig: displayConfigProps = {
		split: isOption1 ? "horizontal" : "vertical",
		minSize: isOption1 ? 100 : 200,
		maxSize: isOption1 ? "80%" : "90%",
		resizerSize: isOption1 ? 0.5 : 0.5,
		defaultSizes: isOption1 ? [500, "60%", "auto"] : [700, "60%", "auto"],
	};

	useEffect(() => {
		if (summary?.orderListArray) {
		  setOrderListId(summary.orderListArray);
		}
	  }, [summary?.orderListArray]);
	  
	const [sizes, setSizes] = useState(displayConfig.defaultSizes);
	const { inBadge, outBadge } = getBadgeStyles(theme);

	const allOrders: any[] =
		orderData?.reduce((accumulator: any[], entry: any) => {
			const filteredOrders = entry.orders.map((order: any) => {
				const { column1, column2, ...rest } = order;
				return rest;
			});
			return accumulator.concat(filteredOrders);
		}, []) || [];

	const shouldDisplayButton = allOrders.some(
		(order) => !["Approved", "Executed"].includes(order.order_status),
	);
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await axios.get(services.ALGO_JOURNAL, {
				params: {
					fund_id: fund_id,
					target_date: target_date,
					target_mode: formattedTargetMode,
					calc_date: calc_date,
				},
			});
			setErrorMessage(null);
			setAlgoJournalData(response.data);
			if (response.data.data === null) {
				setErrorMessage("NO_DATA_FOUND");
				return;
			}
		} catch (error: any) {
			setErrorMessage(error.response.status);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();

		if (fund_id && target_date && formattedTargetMode) {
			dispatch(
				fetchOrderData({
					fund_id,
					target_date,
					target_mode: formattedTargetMode,
					calc_date: calc_date || "",
					fund_state,
					transaction_id,
				}),
			);
		}
	}, [dispatch, fund_id, target_date, formattedTargetMode, calc_date, fund_state, transaction_id]);

	const journalEntries = algoJournalData?.data?.journal_entries || [];

	const calculateTotals = (orderData: any[]) => {
		let totalBuyShare = 0;
		let totalSellShare = 0;
		let totalBuyAmountInMillions = 0;
		let totalSellAmountInMillions = 0;
		let totalBuyPct = 0;
		let totalSellPct = 0;
	
		orderData.forEach((entry) => {
			if (entry?.order_asset_type === ConstantUtils.orderAssetType.orderAssetTypeFutures) {
				totalBuyShare += entry?.futures_stats?.total_buy?.num_shares ?? 0;
				totalSellShare += entry?.futures_stats?.total_sell?.num_shares ?? 0;
				totalBuyAmountInMillions += entry?.futures_stats?.total_buy?.amount ?? 0;
				totalSellAmountInMillions += entry?.futures_stats?.total_sell?.amount ?? 0;
			} else if (entry?.order_asset_type === ConstantUtils.orderAssetType.orderAssetTypeEquity) {
				totalBuyShare += entry?.equity_stats?.total_buy?.num_shares ?? 0;
				totalSellShare += entry?.equity_stats?.total_sell?.num_shares ?? 0;
				totalBuyAmountInMillions += entry?.equity_stats?.total_buy?.amount ?? 0;
				totalSellAmountInMillions += entry?.equity_stats?.total_sell?.amount ?? 0;
			}
		});
	
		const totalNav = fundStats?.total_nav ?? 1; // Avoid division by zero
		totalBuyPct = totalBuyAmountInMillions / totalNav;
		totalSellPct = totalSellAmountInMillions / totalNav;
	
		return {
			totalBuyShare,
			totalSellShare,
			totalBuyAmountInMillions,
			totalSellAmountInMillions,
			totalBuyPct,
			totalSellPct,
		};
	};
	
	const {
		totalBuyShare,
		totalSellShare,
		totalBuyAmountInMillions,
		totalSellAmountInMillions,
		totalBuyPct,
		totalSellPct,
	} = calculateTotals(orderData ?? []);



	const RenderTablesMemoized = React.memo(({ journalEntries, theme }: any) => {
		return (
			<div className='table-container'>
				{errorMessage && <ErrorMessage errorMessage={errorMessage} />}
				{journalEntries.map((entry: any, index: number) => {
					const words = entry.description.split(" ");
					const lastWord = words.pop();
					const isLastWordIn = lastWord?.toUpperCase() === "IN";
					const isLastWordOut = lastWord?.toUpperCase() === "OUT";

					const toHead = (s: string | undefined) => {
						const n = typeof s === "string" ? s.toLowerCase().split("_") : [];
						const conv = n.map((x) => (x && x[0] ? x[0].toLocaleUpperCase() + x.slice(1) : ""));
						return conv.join(" ");
					};

					const obj: KeyValuePairs = {};
					entry?.fund_stats_examined_in_algo?.forEach((e: any) => {
						obj[toHead(e?.fund_target?.type ?? "")] = {
							stats: e.stats,
							violated_tolerance: e.violated_tolerance,
							is_out_of_tolerance: e.is_out_of_tolerance,
							upper_threshold: e.fund_target?.upper_threshold,
							lower_threshold: e.fund_target?.lower_threshold,
						};
					});

					const rightObj: KeyValuePairs = {};
					entry?.fund_stats_not_examined_in_algo?.forEach((e: any) => {
						rightObj[toHead(e?.fund_target?.type ?? "")] = {
							stats: e.stats,
							violated_tolerance: e.violated_tolerance,
							is_out_of_tolerance: e.is_out_of_tolerance,
						};
					});

					const rowDataLeft: rowInfo = obj as rowInfo;
					const rowDataRight: rowInfo = rightObj as rowInfo;

					const bulletSVG =
						index === journalEntries.length - 1 ? (
							<IconWrapper name='purplebullet' className='bullet-image' />
						) : (
							<IconWrapper name='greybullet' className='bullet-image' />
						);

					return (
						<div
							key={index}
							className={`connection-line ${index !== journalEntries.length - 1 ? "has-divider" : ""
								}`}
						>
							{bulletSVG}
							<div>
								<Flex>
									<Text fw={600} size={theme.fontSizes.md} mb={"1rem"} className='description'>
										{words.join(" ")} {isLastWordIn ? inBadge : outBadge}
									</Text>
								</Flex>
								<div className='tablealign'>
									<ThresoldLeft rowData={[rowDataLeft]} />
									{entry?.fund_stats_not_examined_in_algo?.length > 0 ? (
										<div className='tablegap'>
											<div className='vertical-divider'></div>
											<ThresoldRight rowData={[rowDataRight]} />
										</div>
									) : (
										<br />
									)}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	});

	const RenderOrdersMemoized = React.memo(({ allOrders, shouldDisplayButton }: any) => {
		return (
			<Box h={"85vh"}>
				{Array.isArray(allOrders) && allOrders.length > 0 ? (
					<OrderTable
						key='commonHeader'
						orders={allOrders}
						TITLE='Orders'
						initialTotalBuyShares={totalBuyShare}
						initialTotalSellShares={totalSellShare}
						initialTotalBuyAmountInMillions={totalBuyAmountInMillions}
						initialTotalSellAmountInMillions={totalSellAmountInMillions}
						initialTotalBuyPct={totalBuyPct}
						initialTotalSellPct={totalSellPct}
						initialOrderListID={orderListId}
						shouldDisplayButton={shouldDisplayButton}
					/>
				) : (
					<></>
				)}
			</Box>
		);
	});

	return (
		<>
			{orderLoading ? (
				<Box h={"80vh"}>
					<LoaderIcon name='loading-large' alt='loading icon' />{" "}
				</Box>
			) : (
				<>
					{fund_state === "Completed" ? (
						<div className='main-page-padding'>
							<div className='order-table-with-border'>
								<RenderOrdersMemoized allOrders={allOrders} />
							</div>
						</div>
					) : (
						<div className={`flex h-screen ${isOption1 ? "" : "order-option-2"}`}>
							<SplitPane
								split={displayConfig.split}
								sizes={sizes}
								onChange={setSizes}
								resizerSize={displayConfig.resizerSize}
								sashRender={() => <></> /* this is needed due to plugins limitation */}
							>
								<Pane minSize={displayConfig.minSize} maxSize={displayConfig.maxSize}>
									<div className='overflow-x-auto'></div>
									<div className='split-pane'>
										<div className='main-page-padding '>
											<div ref={splitPaneRef} className='tables-container'>
												{isOption1 && (
													<Text size={theme.fontSizes.xl} fw={700}>
														{t("ANALYSIS")}
													</Text>
												)}
												<RenderTablesMemoized
													journalEntries={journalEntries}
													theme={theme}
													errorMessageOrder={errorMessageOrder}
												/>
											</div>
										</div>
									</div>
								</Pane>
								<Pane>
									{orderError ? (
										<Flex
											direction='column'
											align='center'
											style={{ height: "100%", width: "100%", paddingTop: "2rem" }}
										>
											<h2>{t("NO_ORDERS_TITLE")}</h2>
											<div>{t("NO_ORDERS_MESSAGE")}</div>
										</Flex>
									) : (
										<div className='flex grow'>
											<div className='order-table' style={{ paddingLeft: "1.5rem" }}>
												<RenderOrdersMemoized
													allOrders={allOrders}
													shouldDisplayButton={shouldDisplayButton}
												/>
											</div>
										</div>
									)}
								</Pane>
							</SplitPane>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default AnalysisOrderTab;
