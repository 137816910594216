import { useMemo } from "react";
import type { CellClickedEvent, ColDef } from "ag-grid-community";
import { RowInfo } from "../../types";
import { Button, Tooltip } from "@mantine/core";
import IconWrapper from "../Common/IconWrapper";
import { getCellClassForSpendCash } from "../CustomTable/utilsAgTable";
import { useLocation } from "react-router-dom";

type UseColDefsProps = {
	selected: string;
	fundIdHeader: string;
	t: (key: string) => string;
	handleFundIdCellClick: (params: any) => void;
	hasWritePermission: (fundGroup: string, permission: string) => boolean;
	renderCellWithViolations: (...args: any[]) => JSX.Element;
	inBadge: JSX.Element;
	outBadge: JSX.Element;
	cellWithoutBold: any;
	cellicon: any;
	commonCellStyle: any;
	commonCellStyleWithoutTolerance: any;
	wipButtonStates: Record<string, string>;
	handleWipButtonClick: (...args: any[]) => void;
	handleWipButtonHover: (...args: any[]) => void;
	handleWipButtonLeave: (...args: any[]) => void;
	isButtonDisabled: boolean;
	loadingButtons: Record<string, boolean>;
	theme: any;
	setCheckState: (state: string) => void;
	ME: string;
	I_M_STARTING: string;
};

export const useColDefs = ({
	selected,
	fundIdHeader,
	t,
	handleFundIdCellClick,
	hasWritePermission,
	renderCellWithViolations,
	inBadge,
	outBadge,
	cellWithoutBold,
	cellicon,
	commonCellStyle,
	commonCellStyleWithoutTolerance,
	wipButtonStates,
	handleWipButtonClick,
	handleWipButtonHover,
	handleWipButtonLeave,
	isButtonDisabled,
	loadingButtons,
	theme,
	setCheckState,
	ME,
	I_M_STARTING,
}: UseColDefsProps): ColDef[] => {
	const location = useLocation();

	return useMemo<ColDef[]>(() => {
		const defaultCols: ColDef<RowInfo>[] = [
			{
				field: "progress_status",
				headerName: '',
				pinned: "left",
				onCellClicked: (params) => handleFundIdCellClick(params),
				cellStyle: cellWithoutBold,
				cellRenderer: (params: any) => {
					return params.data.progress_status ? (
						<IconWrapper name="progress-ring" className="progress-ring" />
					) : null;
				},
			},
			{
				valueGetter: "node.rowIndex + 1",
				headerName: "",
				width: 50,
				headerClass: `custom-header`,
				pinned: "left",
				cellStyle: {
					textAlign: "right",
				},
			},

			{
				field: "fund_id",
				headerName: fundIdHeader,
				width: 105,
				minWidth: 100,
				pinned: "left",
				onCellClicked: (params) => handleFundIdCellClick(params),
				cellStyle: cellWithoutBold,
			},

			{
				field: "fund_name",
				headerName: t("FUND_NAME"),
				width: 248,
				minWidth: 100,
				pinned: "left",
				onCellClicked: (params) => handleFundIdCellClick(params),
				cellStyle: cellWithoutBold,
				resizable: true,
			},
			{
				field: "tol_start",
				headerName: t("START_OF_THE_DAY_TOL_STATUS"),
				width: 135,
				minWidth: 100,
				cellStyle: cellicon,
				onCellClicked: (params: CellClickedEvent<RowInfo, any>) => handleFundIdCellClick(params),
				cellRenderer: (params: any) => {
					const tolStart = params.data.tol_start;
					return <div>{tolStart === "IN" ? inBadge : outBadge}</div>;
				},
			},

			{
				field: "tol_current",
				headerName: t("CURRENT_TOL_STATUS"),
				width: 115,
				minWidth: 100,
				cellStyle: cellicon,
				onCellClicked: (params: CellClickedEvent<RowInfo, any>) => handleFundIdCellClick(params),
				cellRenderer: (params: any) => {
					const tolCurrent = params.data.tol_current;
					if (tolCurrent !== null && tolCurrent !== undefined) {
						return <div>{tolCurrent === "IN" ? inBadge : outBadge}</div>;
					} else {
						return null;
					}
				},
			} as ColDef<RowInfo>,

			{
				field: "data_missing",
				headerName: `${t("DATA_MISSING")}`,
				width: 100,
				minWidth: 100,
				cellStyle: commonCellStyle,
			},

			{
				field: "wic",
				headerName: `${t("WORK_IN_COMPLETED_BY")}`,
				width: 120,
				minWidth: 100,
				cellStyle: commonCellStyle,
			},

			{
				field: "order_status",
				headerName: t("ORDER_STATUS"),
				width: 113,
				minWidth: 120,
				onCellClicked: (params) => handleFundIdCellClick(params),
			},

			{
				field: "primary_fund_manager",
				headerName: t("PRIMARY_FUND_MANAGER"),
				width: 113,
				minWidth: 120,
				onCellClicked: (params) => handleFundIdCellClick(params),
			},

			{
				field: "wip",
				headerName: t("WORK_IN_PROGRESS_BY"),
				width: 126,
				minWidth: 126,
				headerClass: "common-header-right",
				cellStyle: {
					...commonCellStyle,
					textAlign: "center",
					marginTop: "-0.05rem",
				},
				cellRenderer: (params: any) => {
					const { wip, is_fund_locked, fund_group, sr_no: rowId, order_status } = params.data;
					const hasPermission = hasWritePermission(fund_group, "write");
					const buttonState = wipButtonStates[rowId];
					const isLoading = loadingButtons[rowId];
					const isOrderNotReady =
						order_status === t("FUND_STATE_NOT_READY") ||
						order_status === t("FUND_STATE_COMPLETED");
					const isButtonDisabled = !hasPermission || isLoading;
				
					if (isOrderNotReady) return null;
				
					const buttonProps = {
						className: `wippillstyle ${buttonState === (is_fund_locked === "true" ? ME : I_M_STARTING)}`,
						loaderProps: { type: "dots" },
						loading: isLoading,
						style: { backgroundColor: isLoading ? theme.colors.violet[8] : undefined },
						disabled: isButtonDisabled,
					};
				
					const getButtonContent = () =>
						isLoading ? null : buttonState || (is_fund_locked === "true" ? ME : I_M_STARTING);
				
					if (!hasPermission) {
						return (
							<Tooltip
								label={t('NO_PERMISSION_TOOLTIP')}
								position="bottom"
								withArrow
								bg={'#f7f9f9'}
								multiline
								w={140}
								style={{
									color: 'black',
									borderRadius: '10px',
									fontWeight: '600',
									boxShadow: '0 0 0 0.5px rgba(0, 0, 0, 0.05), 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.3)',
								}}
							>
								<Button {...buttonProps} className="wippillstyle-disable" disabled>
									{buttonState || I_M_STARTING}
								</Button>
							</Tooltip>
						);
					}

					return (
						<Button
							{...buttonProps}
							onClick={() => handleWipButtonClick(rowId, params)}
							onMouseEnter={is_fund_locked === "true" ? () => handleWipButtonHover(rowId) : undefined}
							onMouseLeave={is_fund_locked === "true" ? () => handleWipButtonLeave(rowId) : undefined}
						>
							{getButtonContent()}
						</Button>
					);
				},
				
			},
			
			{
				field: "spend_cash_bef",
				headerName: t("SPENDABLE_CASH_BEF"),
				width: 105,
				minWidth: 100,
				cellStyle: commonCellStyle,
				headerClass: "common-header-right align-header-right",
				onCellClicked: handleFundIdCellClick,
				cellClass: (params: any) => getCellClassForSpendCash(params, 'spend_cash_bef'),
				cellRenderer: (params: any) =>
					renderCellWithViolations(
						params,
						"spend_cash_bef",
						"spend_cash_bef_violated_tolerances",
						"spend_cash_bef_violated_tolerances_lower",
						"spend_cash_bef_violated_tolerances_upper",
						"spend_cash_bef",
					),
			},
			{
				field: "spend_cash_aft",
				headerName: t("SPENDABLE_CASH_AFT"),
				width: 105,
				minWidth: 100,
				headerClass: "common-header-right align-header-right",
				cellStyle: commonCellStyle,
				onCellClicked: handleFundIdCellClick,
				cellClass: (params: any) => getCellClassForSpendCash(params, 'spend_cash_aft'),
				cellRenderer: (params: any) =>
					renderCellWithViolations(
						params,
						"spend_cash_aft",
						"spend_cash_aft_violated_tolerances",
						"spend_cash_aft_violated_tolerances_lower",
						"spend_cash_aft_violated_tolerances_upper",
						"spend_cash_aft",
					),
			},
			{
				field: "unequitized_cash_bef",
				headerName: t("UNEQUITIZED_CASH_BEF"),
				headerClass: "common-header-right align-header-right",
				width: 105,
				minWidth: 100,
				cellStyle: commonCellStyle,
				onCellClicked: handleFundIdCellClick,
				cellClass: (params: any) => getCellClassForSpendCash(params, 'unequitized_cash_bef'),
				cellRenderer: (params: any) =>
					renderCellWithViolations(
						params,
						"unequitized_cash_bef",
						"unequitized_cash_bef_violated_tolerances",
						"unequitized_cash_bef_violated_tolerances_lower",
						"unequitized_cash_bef_violated_tolerances_upper",
						"unequitized_cash_bef",
					),
			},
			{
				field: "unequitized_cash_aft",
				headerName: t("UNEQUITIZED_CASH_AFT"),
				headerClass: "common-header-right align-header-right",
				width: 105,
				minWidth: 100,
				cellStyle: commonCellStyle,
				onCellClicked: handleFundIdCellClick,
				cellClass: (params: any) => getCellClassForSpendCash(params, 'unequitized_cash_aft'),
				cellRenderer: (params: any) =>
					renderCellWithViolations(
						params,
						"unequitized_cash_aft",
						"unequitized_cash_aft_violated_tolerances",
						"unequitized_cash_aft_violated_tolerances_lower",
						"unequitized_cash_aft_violated_tolerances_upper",
						"unequitized_cash_aft",
					),
			},

			{
				field: "assets",
				headerName: `${t("ASSET_MAX_ACT_WGT")}`,
				headerClass: "common-header-right align-header-right",
				width: 110,
				minWidth: 100,
				cellStyle: commonCellStyle,
				onCellClicked: handleFundIdCellClick,
				cellClass: (params: any) => getCellClassForSpendCash(params, 'asset_max_active_wgt'),
				cellRenderer: (params: any) =>
					renderCellWithViolations(
						params,
						"assets",
						"asset_max_active_wgt_violated_tolerances",
						"asset_max_active_wgt_violated_tolerances_lower",
						"asset_max_active_wgt_violated_tolerances_upper",
						"assets",
					),
			},
			{
				field: "industry",
				headerName: `${t("INDUSTRY_MAX_ACT_WGT")}`,
				headerClass: "common-header-right align-header-right",
				width: 120,
				minWidth: 100,
				cellStyle: commonCellStyle,
				onCellClicked: handleFundIdCellClick,
				cellClass: (params: any) => getCellClassForSpendCash(params, 'sector_max_active_wgt'),
				cellRenderer: (params: any) =>
					renderCellWithViolations(
						params,
						"industry",
						"sector_max_active_wgt_violated_tolerances",
						"sector_max_active_wgt_violated_tolerances_lower",
						"sector_max_active_wgt_violated_tolerances_upper",
						"industry",
					),
			},
			{
				field: "country",
				headerName: `${t("COUNTRY_MAX_ACT_WGT")}`,
				headerClass: "common-header-right align-header-right",
				width: 115,
				minWidth: 100,
				cellStyle: commonCellStyle,
				onCellClicked: handleFundIdCellClick,
				cellClass: (params: any) => getCellClassForSpendCash(params, 'country_max_active_wgt'),
				cellRenderer: (params: any) =>
					renderCellWithViolations(
						params,
						"country",
						"country_max_active_wgt_violated_tolerances",
						"country_max_active_wgt_violated_tolerances_lower",
						"country_max_active_wgt_violated_tolerances_upper",
						"country",
					),
			},
			{
				field: "currency",
				headerName: `${t("CURRENCY_MAX_ACT_WGT")}`,
				headerClass: "common-header-right align-header-right",
				width: 115,
				minWidth: 100,
				cellStyle: commonCellStyle,
				onCellClicked: handleFundIdCellClick,
				cellClass: (params: any) => getCellClassForSpendCash(params, 'currency_max_active_wgt'),
				cellRenderer: (params: any) =>
					renderCellWithViolations(
						params,
						"currency",
						"currency_max_active_wgt_violated_tolerances",
						"currency_max_active_wgt_violated_tolerances_lower",
						"currency_max_active_wgt_violated_tolerances_upper",
						"currency",
					),
			},
			
			{
				field: "size",
				headerName: `${t("SIZE_MAX_ACT_WGT")}`,
				headerClass: "common-header-right align-header-right",
				width: 100,
				minWidth: 100,
				cellStyle: commonCellStyleWithoutTolerance,
				onCellClicked: (params) => handleFundIdCellClick(params),
			},
			{
				field: "shortpos",
				headerName: `# ${t("SHORT_POSITIONS")}`,
				headerClass: "common-header-right align-header-right",
				width: 90,
				minWidth: 100,
				cellStyle: commonCellStyleWithoutTolerance,
				onCellClicked: (params) => handleFundIdCellClick(params),
			},
			{
				field: "nohold",
				headerName: `# ${t("NO_HOLD")}`,
				headerClass: "common-header-right align-header-right",
				width: 94,
				minWidth: 100,
				cellStyle: commonCellStyleWithoutTolerance,
				onCellClicked: (params) => handleFundIdCellClick(params),
			},
			{
				field: "nonbench",
				headerName: `${t("NON_BENCHMARK")}`,
				headerClass: "common-header-right align-header-right",
				width: 100,
				minWidth: 100,
				cellStyle: commonCellStyleWithoutTolerance,
				onCellClicked: (params) => handleFundIdCellClick(params),
			},
			{
				field: "activerisk",
				headerName: `${t("ACTIVE_RISK")}`,
				headerClass: "common-header-right align-header-right",
				width: 100,
				minWidth: 100,
				cellStyle: commonCellStyleWithoutTolerance,
				onCellClicked: (params) => handleFundIdCellClick(params),
			},
			{
				field: "equityactiverisk",
				headerName: `${t("EQUITY_ACTIVE_RISK")}`,
				headerClass: "common-header-right align-header-right",
				width: 110,
				minWidth: 100,
				cellStyle: commonCellStyleWithoutTolerance,
			},
		];

		let fieldsToExclude: string[] = [];
		if (selected === "COMPLETED") {
			fieldsToExclude = ["order_status", "wip", "data_missing", "primary_fund_manager"];
		}
		if (selected === "NOT_READY") {
			fieldsToExclude = ["wic", "wip", "primary_fund_manager"];
		}
		if (selected === "READY") {
			fieldsToExclude = ["data_missing", "wic", "primary_fund_manager"];
		}


		return defaultCols.filter((col) => !fieldsToExclude.includes(col.field || ""));
	}, [selected, fundIdHeader, t, handleFundIdCellClick, hasWritePermission, renderCellWithViolations, inBadge, outBadge, cellWithoutBold, cellicon, commonCellStyle, commonCellStyleWithoutTolerance, wipButtonStates, handleWipButtonClick, handleWipButtonHover, handleWipButtonLeave, loadingButtons, theme, ME, I_M_STARTING]);
};
