import { ChatList, NewCategorizedChats } from "../store/features/chat/types";
import { getDaysDifferenceFromToday } from "./calendarUtils";

export const newCategorizeChats = (chatList: ChatList[]) => {
	const returnObj: NewCategorizedChats = {
		today: [],
		yesterday: [],
		last7Days: [],
	};
	chatList.forEach((chat) => {
		const daysDifference = getDaysDifferenceFromToday(chat.chat_date) as number;
		if (daysDifference === 0) {
			returnObj.today.push(chat);
		} else if (daysDifference === -1) {
			returnObj.yesterday.push(chat);
		} else if (daysDifference < -1) {
			returnObj.last7Days.push(chat);
		}
	});
	return returnObj;
};
