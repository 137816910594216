import React, { useState } from 'react';
import { Modal, Text, Flex, useMantineTheme, Center } from '@mantine/core';
import CustomButton from '../Common/Buttons/CustomButton';
import { t } from 'i18next';
import { Dropzone } from '@mantine/dropzone';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import IconWrapper from './IconWrapper';
import { services } from '../../services';
import { convertToLowerCaseCamelCase, parseCSV, stringToBoolean } from '../../utils/conversions';
import { routes } from '../../routes';
import { createFundPayload } from '../createImportFundPayload';

interface ImportModalProps {
    opened: boolean;
    onClose: () => void;
    TITLE: string;
    centered?: boolean;
    withCloseButton?: boolean;
    radius?: string;
}

export const ImportModal: React.FC<ImportModalProps> = ({
    opened,
    onClose,
    TITLE,
    centered = true,
    withCloseButton = false,
    radius = '1.875rem',
}) => {
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success' | 'error'>('idle');
    const theme = useMantineTheme();
    const navigate = useNavigate();

    const handleDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file) {
            setUploadStatus('uploading');
            setFiles([file]);

            setTimeout(() => {
                setUploadStatus('success');
            }, 1000);
        } else {
            setUploadStatus('error');
        }
    };

    const handleSubmit = async () => {
        if (files.length === 0) return;
    
        try {
            setLoading(true);
            setUploadStatus("uploading");
            const data: any[] = (await parseCSV(files[0])) as any[];
    
            for (const row of data) {
                const normalizedAssetClass = row["Asset Class"]?.trim();
                const normalizedInstrument = row["Instrument"]?.trim();
    
                const optionalParams = [
                    "Cuspi",
                    "Sedol",
                    "ISIN",
                    "Bloomberg Ticker",
                    "Reuters Identifier",
                    "Expiry Date",
                    "Msci Id",
                    "Barchart Id",
                ];
    
                let assetIdType = "";
                let assetId = "";
    
                for (const param of optionalParams) {
                    if (row[param]) {
                        assetIdType = param;
                        assetId = row[param].trim();
                        break;
                    }
                }
    
                const commonPayload: Record<string, any> = {
                    asset_name: row["Asset Name"],
                    currency: row["Currency"],
                    issue_country: row["Issue Country"],
                    trading_country: row["Trading Country"],
                    risk_country: row["Risk Country"],
                    sector: row["Sector"],
                };
    
                optionalParams.forEach((param) => {
                    if (row[param]) {
                        commonPayload[`asset_key.${convertToLowerCaseCamelCase(param)}`] = row[param].trim();
                    }
                });
    
                const isFundPayloadCreated = await createFundPayload(row);
                if (isFundPayloadCreated) {
                    continue; 
                }

                if (normalizedAssetClass === "Equity" && normalizedInstrument === "Stock") {
                    const payload = {
                        equity: {
                            asset_type: "STOCK",
                            primary_exchange: row["Primary Exchange"],
                            parent_asset_id: row["Parent Asset Id"],
                            underlying_asset_id: row["Underlying Asset Id"],
                            ratio_to_underlying_asset: row["Ratio to Underlying Asset"],
                            equivalent_voting_rights: row["Equivalent Voting Rights"],
                            price_multiplier: row["Price Multiplier"],
                        },
                        asset_group: "EQUITY",
                        not_tradable: stringToBoolean(row["Tradable Asset"]),
                        ...commonPayload,
                    };
    
                    let queryParams = new URLSearchParams({ created_by: "CREATED_BY_USER" });
    
                    if (assetIdType && assetId) {
                        queryParams.append("asset_id_type", assetIdType);
                        queryParams.append("asset_id", assetId);
                    }
    
                    const apiUrl = `${services.ASSET_INVESTMENT}?created_by=CREATED_BY_USER`;
    
                    await axios.post(apiUrl, [payload]);
                }
            }
    
            setUploadStatus("success");
            onClose();
            navigate(-1);
        } catch (error) {
            console.error("Error processing CSV:", error);
            setUploadStatus("error");
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            centered={centered}
            withCloseButton={withCloseButton}
            radius={radius}
            className='import-modal'
            size={'29.375rem'}
        >
            <Text ta={'center'} size='lg' fw={700} mt={"md"}>
                {t(TITLE)}
            </Text>

            <Dropzone
                onDrop={handleDrop}
                className='asset-dropzone'
                maxFiles={1}
                multiple={false}
                accept={['text/csv']}
            >
                {uploadStatus === 'uploading' && (
                    <Flex justify={'center'} mt={'-0.8rem'}>
                        <IconWrapper name='loading-small' className='import-loader' />
                    </Flex>
                )}

                {uploadStatus === 'idle' && (
                    <Text ta="center" c={theme.colors.grey[5]}>
                        {t("DROPZONE_MESSAGE")}
                    </Text>
                )}

                {uploadStatus === 'error' && (
                    <Text ta="center" c={theme.colors.red[0]}>
                        {t("DROPZONE_ERROR_MESSAGE")}
                    </Text>
                )}

                {uploadStatus === 'success' && (
                    <Text ta="center" c={theme.colors.grey[5]}>
                        {t("DROPZONE_MESSAGE_SUCCESS")}
                    </Text>
                )}
            </Dropzone>

            <Flex justify="center" align="center" mt="2.1rem" mr={'xxl'} mb={"2.2rem"}>
                <CustomButton variant='cancel' onClick={onClose}>
                    {t("CANCEL")}
                </CustomButton>
                <CustomButton variant='submit' onClick={handleSubmit} disabled={files.length === 0 || loading}>
                    {t("IMPORT")}
                </CustomButton>
            </Flex>
        </Modal>
    );
};

