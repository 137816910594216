import { Order } from "./hooks/useOrders";

export type TabTypes = "MARKET_ON_OPEN" | "MARKET" | "MARKET_ON_CLOSE" | "ALL";

export const filterOrders = (orders: Order[], tab: TabTypes): Order[] => {
	const orderTypeMapping: Record<TabTypes, string | undefined> = {
		MARKET_ON_OPEN: "ORDER_TYPE_MARKET_ON_OPEN",
		MARKET: "ORDER_TYPE_MARKET",
		MARKET_ON_CLOSE: "ORDER_TYPE_MARKET_ON_CLOSE",
		ALL: "ALL",
	};

	const selectedOrderType = orderTypeMapping[tab];

	if (selectedOrderType === "ALL") {
		return orders.map((el,index)=>({...el,sr_no:index+1}))
	}

	return orders.filter((order) => order.order_type === selectedOrderType).map((el, index) => ({...el,
		sr_no: index + 1,}))
};

export const nonPercentageFields: string[] = [
	"Max Number Of Short Positions",
	"Another Field Name", 
  ];
  