import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import {
	CellClickedEvent,
	CellValueChangedEvent,
	ColDef,
	GridOptions,
	RowClassParams,
	RowDoubleClickedEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CustomNoRowsOverlay from "../Common/customNoRowsOverlay";
import { t } from "i18next";
import { customHeight, rowHeightLargeRem, rowHeightLarge, rowHeightRem } from "./utilsAgTable";
import { useMantineTheme } from "@mantine/core";
import { getBadgeStyles } from "../../utils/getStyles";

interface AgGridTableProps {
	rowData: any[];
	columnDefs: ColDef[];
	defaultColDef?: any;
	rowSelection?: "single" | "multiple";
	rowHeight?: number;
	onSelectionChanged?: (event: any) => void;
	onGridReady?: (params: any) => void;
	customHeight?: string;
	onRowDoubleClicked?: (event: RowDoubleClickedEvent) => void;
	onCellValueChanged?: (event: CellValueChangedEvent) => void;
	onCellClicked?: (event: CellClickedEvent) => void;
	getRowClass?: (params: RowClassParams) => string | string[] | undefined;
	domLayout?: any;
	getRowStyle?: any;
	noHeight?: boolean;
	onSortChanged?: any;
	onCellDoubleClicked?: any;
	className?: string;
	largeHeader?: boolean;
	maxConcurrentDatasourceRequests?: number;
	maxBlocksInCache?: number;
	getRowId?: any;
	emptyMsg?: string;
	fullLength?: boolean;
	maxHeight?: string;
	height?: string;
	gridOptionsProp?: any;
}

const CustomTable: React.FC<AgGridTableProps> = ({
	rowData,
	columnDefs,
	rowSelection = "single",
	rowHeight = 30,
	onSelectionChanged,
	onGridReady,
	defaultColDef,
	onRowDoubleClicked,
	onCellValueChanged,
	onCellClicked,
	getRowClass,
	domLayout,
	getRowStyle,
	noHeight,
	onSortChanged,
	onCellDoubleClicked,
	className,
	largeHeader,
	maxConcurrentDatasourceRequests,
	maxBlocksInCache,
	getRowId,
	emptyMsg,
	fullLength,
	maxHeight,
	height,
	gridOptionsProp,
}) => {
	const gridApiRef = useRef<any>(null);
	const theme = useMantineTheme()
	const gridOptions: GridOptions = useMemo(
		() => ({
			suppressRowHoverHighlight: true,
			immutableData: true,
		}),
		[],
	);

	const noRowsOverlayComponentParams = useMemo(
		() => ({
			noRowsMessageFunc: () => `- ${t(emptyMsg || "NO_FUNDS_HERE")} -`,
		}),
		[emptyMsg],
	);

	const adjustedRowData = useMemo(() => {
		if (fullLength) {
			return [...rowData, { id: "empty-row", isEmpty: true }];
		}
		return rowData;
	}, [rowData, fullLength]);

	const getRowHeight = useCallback((params: any) => {
		if (params.data?.isEmpty) {
			return 11; 
		}
		return rowHeight;
	}, [rowHeight]);


	const classNameCheck = useMemo(() => `${className} ag-theme-quartz`, [className]);

	const tableHeightWithRows = rowHeight === rowHeightLarge ? rowHeightLargeRem : rowHeightRem;
	const tableLargeHeader = largeHeader === true ? 3.2 : 2;
	const calculatedMaxHeight = maxHeight ?? (fullLength ? "80vh" : "33.9rem");

	const [isHeightExceeded, setIsHeightExceeded] = useState(false);

	const defaultGetRowClass = useCallback((params: RowClassParams): string => {
		return params.data.disabled ? "disabled-row" : "";
	}, []);

	useEffect(() => {
		const customHeightValue = parseFloat(
			customHeight(rowData, tableLargeHeader, tableHeightWithRows),
		);
		const calculatedMaxHeightValue = parseFloat(calculatedMaxHeight);

		if (calculatedMaxHeight) {
			const isHeightExceeded = customHeightValue > calculatedMaxHeightValue;
			setIsHeightExceeded(isHeightExceeded);
		}
	}, [rowData, maxHeight, tableLargeHeader, tableHeightWithRows, calculatedMaxHeight]);

	const getAdditionalRowStyle = (params: any) => {
		if (params.node.rowIndex === params.api.getDisplayedRowCount() - 1) {
			return { borderBottom: `0.5px solid lightgrey` };
		}
		return {};
	};

	const styles = getBadgeStyles(theme);

	const updatedColumnDefs: ColDef[] = [
		...columnDefs.map((col, index) => ({
			...col,
		})),
		...(isHeightExceeded
			? [
				{
					field: "empty",
					headerName: "",
					width: 10.8,
					minWidth: 10.8,
					flex: 0,
					cellStyle: {
						...styles.scrollBorder,
						padding: 0,
						margin: 0,
					},
					cellRenderer: () => {
						return '';
					},
					headerClass: 'custom-header-left-border'
				}
			]
			: []),
	];

	return (
		<div
			className={`${classNameCheck || "fund-query ag-theme-quartz"} table-container`}
			style={{
				height: noHeight
					? height || "auto"
					: customHeight(rowData, tableLargeHeader, tableHeightWithRows),
				maxHeight: calculatedMaxHeight,
				width: '100%'
			}}
		>
			<AgGridReact
				ref={gridApiRef}
				rowData={adjustedRowData}
				columnDefs={updatedColumnDefs}
				defaultColDef={defaultColDef}
				rowSelection={rowSelection}
				getRowHeight={getRowHeight}
				rowMultiSelectWithClick={true}
				onSelectionChanged={onSelectionChanged}
				domLayout={domLayout}
				noRowsOverlayComponent={CustomNoRowsOverlay}
				noRowsOverlayComponentParams={noRowsOverlayComponentParams}
				gridOptions={gridOptionsProp || gridOptions}
				onRowDoubleClicked={onRowDoubleClicked}
				onCellValueChanged={onCellValueChanged}
				onCellClicked={onCellClicked}
				getRowClass={getRowClass || defaultGetRowClass}
				getRowStyle={(params) => ({
					...(getRowStyle ? getRowStyle(params) : {}),
					...getAdditionalRowStyle(params),
				})}
				onSortChanged={onSortChanged}
				onCellDoubleClicked={onCellDoubleClicked}
				getRowId={getRowId}
				maxConcurrentDatasourceRequests={maxConcurrentDatasourceRequests}
				maxBlocksInCache={maxBlocksInCache}
				onGridReady={(params) => {
					onGridReady && onGridReady(params);
				}}
			/>

			{isHeightExceeded && (<div className='vertical-scroll-table' />)}
		</div>
	);
};

export default React.memo(CustomTable, (prevProps, nextProps) => {
	return prevProps.rowData === nextProps.rowData && prevProps.columnDefs === nextProps.columnDefs;
});

