import axios from 'axios';
import { routes } from '../routes';

export const createFundPayload = async (row: Record<string, any>) => {
    if (!row['fund_structure']) return;

    const payload = {
        fund_name: row['fund_name'],
        primary_fund_manager: row['primary_fund_manager'],
        fund_currency: row['fund_currency'],
        fund_domicile: row['fund_domicile'],
        legal_jurisdiction: row['legal_jurisdiction'],
        benchmark_id: row['benchmark_id'],
        fund_structure: row['fund_structure'],
        fund_id: row['fund_id'],
        fund_group: row['fund_group'],
        fund_managers: [""],
        valuation_type: row['valuation_type'],
        optimal_futures_basket_id: row['optimal_futures_basket_id'],
        forty_act_fund: row['forty_act_fund'] === 'true',
        qualified_institutional_investor: false,
        qualified_sophisticated_investor: false,
        eligible_under_rule_144a: false,
        parent_fund_id: row['parent_fund_id'] || '',
        market_on_open_activity_allowed: false,
    };

    if (window.location.pathname === routes.CREATE_FUND) {
        await axios.post('/api/fund-attributes', [payload]);
        return true;
    }
    return false;
};
