import { Button, Flex, TextInput, Grid, Box } from "@mantine/core";
import SavePresetModal from "../SavePresetModal";
import { t } from "i18next";
import React, { useCallback } from "react";
import FormField from "./FormField";
import CheckboxGroup from "./CheckboxGroup";
import SelectInput from "./SelectInput";
import DateSelector from "./DateSelector";
import clsx from "clsx";
import { QueryFormDataProp } from "../../../types";

export const initialQueryFormData = {
	fundGroup: "",
	fundId: "",
	assetIdType: "",
	assetId: "",
	selectedTypeOfData: null,
	checkBoxConfig: [],
	selectedFromDate: null,
	selectedToDate: null,
};

export const QueryForm = (props: any) => {
	const {
		queryFormData,
		setQueryFormData,
		toggleSavePresetModal,
		handleSubmit,
		presetName,
		setPresetName,
		isSavePresetModalOpen,
		handleSave,
		selectedFolderName,
		setSelectedFolderName,
	} = props;

	const checkboxConfig: Record<string, { key: string; label: string, value: boolean }[]> = {
		DATA_TYPE_ORDERS: [
			{ key: "approved", label: "APPROVED", value: false },
			{ key: "proposed", label: "PROPOSED", value: false },
			{ key: "executed", label: "EXECUTED", value: false },
		],
		// DATA_TYPE_TRADES: [
		// 	{ key: "executed", label: "EXECUTED", value: false },
		// 	{ key: "confirmed", label: "CONFIRMED", value: false },
		// 	{ key: "unsettled", label: "UNSETTLED", value: false },
		// ],
	};

	const toggleCheckboxState = (key: string) => {
		setQueryFormData((prevFormData: QueryFormDataProp) => {
			return {
				...prevFormData,
				checkBoxConfig: queryFormData.checkBoxConfig.map((eachCheckBox: any) => eachCheckBox.key === key ? { ...eachCheckBox, value: !eachCheckBox.value } : eachCheckBox)
			};
		})
	};

	const handleKeyPress = useCallback(
		(event: { key: string }) => {
			if (event.key === "Enter") handleSave();
		},
		[handleSave],
	);

	const isDisabled = !(
		(queryFormData.fundGroup || queryFormData.fundId) &&
		queryFormData.selectedTypeOfData &&
		(
			queryFormData.selectedTypeOfData === "DATA_TYPE_TARGETS_AND_TOLERANCES" ||
			(queryFormData.selectedFromDate && queryFormData.selectedToDate)
		)
	);

	const handleQueryFormDataChange = (key: string, value: string | Date) => {
		if (key === 'selectedTypeOfData') {
			setQueryFormData((prevFormData: QueryFormDataProp) => {
				return value === 'DATA_TYPE_ORDERS' ? { ...prevFormData, [key]: value, checkBoxConfig: checkboxConfig[value] } : { ...prevFormData, [key]: value, checkBoxConfig: [] }
			})
		} else {
			setQueryFormData((prevFormData: QueryFormDataProp) => {
				return { ...prevFormData, [key]: value }
			})
		}
	}

	return (
		<div className='fund-query-container'>
			<Grid columns={10} mb='sm'>
				<FormField
					label={t("FUND_GROUP")}
					input={
						<TextInput size='xs' radius='sm' variant='filled' placeholder={t("Ex: ALL-INDEX")} />
					}
					inputProps={{
						value: queryFormData.fundGroup,
						onChange: ((e: React.ChangeEvent<HTMLInputElement>) => handleQueryFormDataChange('fundGroup', e.currentTarget.value)),
						disabled: !!queryFormData.fundId,
					}}
					pb={3}
				/>
				<FormField
					label={t("FUND_ID")}
					input={
						<TextInput
							size='xs'
							radius='sm'
							variant='filled'
							placeholder={t("Ex: ACU_001, ACU_R1")}
						/>
					}
					inputProps={{
						value: queryFormData.fundId,
						onChange: ((e: React.ChangeEvent<HTMLInputElement>) => handleQueryFormDataChange('fundId', e.currentTarget.value)),
						disabled: !!queryFormData.fundGroup,
					}}
					pb={27}
				/>
				<FormField
					label={t("ASSET_ID_TYPE")}
					input={
						<SelectInput
							label={t("ASSET_ID_TYPE")}
							data={[
								{ value: "ASSET_KEY_TYPES_SEDOL", label: t("SEDOL") },
								{ value: "ASSET_KEY_TYPES_CUSIP", label: t("CUSIP") },
								{ value: "ASSET_KEY_TYPES_ISIN", label: t("ISIN") },
								{ value: "ASSET_KEY_TYPES_BLOOMBERG_TICKER", label: t("BLOOMBERG_TICKER") },
								{ value: "ASSET_KEY_TYPES_ACUMAIN_ASSET_ID", label: t("ACUMAIN_ASSET_ID") },
							]}
							value={queryFormData.assetIdType}
							onChange={(value) => handleQueryFormDataChange('assetIdType', value as string)}
							placeholder={t("Select Asset ID Type")}
							size='xs'
						/>
					}
				/>
				<FormField
					label={t("ASSET_ID")}
					input={<TextInput size='xs' radius='sm' variant='filled' />}
					inputProps={{
						value: queryFormData.assetId,
						onChange: ((e: React.ChangeEvent<HTMLInputElement>) => handleQueryFormDataChange('assetId', e.currentTarget.value)),
					}}
					pb={28}
				/>
				<FormField
					label={t("TYPE_OF_DATA")}
					input={
						<>
							<SelectInput
								label={t("TYPE_OF_DATA")}
								data={[
									{ value: "DATA_TYPE_ASSETS", label: t("HOLDINGS") },
									{ value: "DATA_TYPE_ORDERS", label: t("ORDERS") },
									// { value: "DATA_TYPE_TRADES", label: t("TRADES") }, //temp commented out
									{ value: "DATA_TYPE_BENCHMARK_CHANGES", label: t("INDEX_CHANGE") },
									{ value: "DATA_TYPE_TARGETS_AND_TOLERANCES", label: t("TARGETS_TOLS") },
									{ value: "DATA_TYPE_CASH_FLOWS", label: t("CASHFLOWS") },
								]}
								value={queryFormData.selectedTypeOfData}
								onChange={(value) => handleQueryFormDataChange('selectedTypeOfData', value as string)}
								placeholder={t("Select Type of Data")}
							/>
							<CheckboxGroup
								checkboxConfig={checkboxConfig[queryFormData.selectedTypeOfData] || []}
								checkboxState={queryFormData.checkBoxConfig}
								onCheckboxChange={toggleCheckboxState}
							/>
						</>
					}
					pb={30}
				/>
				<FormField
					label={t("FROM")}
					input={
						<DateSelector
							label='FROM'
							selectedDate={queryFormData.selectedFromDate}
							onSelect={(date) => handleQueryFormDataChange('selectedFromDate', date as Date)}
							placeholder={t("Select Date")}
							maxDate={queryFormData.selectedToDate}
						/>
					}
				/>
				<FormField
					label={t("TO")}
					input={
						<DateSelector
							label='TO'
							selectedDate={queryFormData.selectedToDate}
							onSelect={(date) => handleQueryFormDataChange('selectedToDate', date as Date)}
							placeholder={t("Select Date")}
							minDate={queryFormData.selectedFromDate}
						/>
					}
				/>
			</Grid>

			<div className='buttonsection'>
				<Flex
					direction='column'
					align='center'
					justify='center'
					mt='lg'
					className='button-container'
				>
					<Button className='get-data-button' onClick={handleSubmit} disabled={isDisabled}>
						{t("GET_DATA")}
					</Button>

					<Button
						className={clsx("save-preset-button", { "custom-disabled": isDisabled })}
						onClick={toggleSavePresetModal}
						disabled={isDisabled}
					>
						{t("SAVE_AS_PRESET")}
					</Button>
				</Flex>
			</div>
			<SavePresetModal
				isOpen={isSavePresetModalOpen}
				onClose={toggleSavePresetModal}
				presetName={presetName}
				setPresetName={setPresetName}
				handleSave={handleSave}
				selectedFolderName={selectedFolderName}
				setSelectedFolderName={setSelectedFolderName}
				handleKeyPress={handleKeyPress}
			/>
		</div>
	);
};
