import { Dispatch } from "@reduxjs/toolkit";
import { services } from "../../../services";
import { setCurrentThreadChat } from "./newChatSlice";
import { ChatQuestionAnswer } from "./types";

export const fetchStreamResponse = async (
	symbol: string,
	question: string,
	messageCreationTime: string,
	updatedThreadChat: ChatQuestionAnswer[],
	dispatch: Dispatch,
) => {
	const chunkResponse = await fetch(`${services.STREAM_CHAT}?ticker_symbol=${symbol}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		credentials: "include",
		body: JSON.stringify({ question }),
	});

	if (!chunkResponse.body) {
		throw new Error("ReadableStream not supported in this environment.");
	}
	const reader = chunkResponse.body.getReader();
	const decoder = new TextDecoder();
	let progressiveChunk = "";
	while (true) {
		const { done, value } = await reader.read();
		if (done) return updatedThreadChat;
		const partialChunk = decoder.decode(value, { stream: true });
		progressiveChunk += partialChunk;

		const updatedThread = updatedThreadChat.map((qa) =>
			qa.creation_time === messageCreationTime ? { ...qa, answer: progressiveChunk } : qa,
		);
		dispatch(setCurrentThreadChat(updatedThread));
	}
};