import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	ChatHistoryPayload,
	ChatList,
	ChatMetadata,
	ChatQuestionAnswer,
	GetMessageResponse,
	PostMessagePayload,
	PostMessageResponse,
	ThreadChatResponseAlpine,
	TranscriptSummary,
} from "./types";
import { services } from "../../../services";
import axios from "axios";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import { clearAllStorage } from "../../../utils/storageUtils";
import { fetchStreamResponse } from "./newChatSliceHelper";

interface ChatState {
	chatList: ChatList[];
	currentThreadChat: ChatQuestionAnswer[];
	earningTranscriptSummary: TranscriptSummary | null;
	input: string;
	error: string | null;
	isLoading: boolean;
	lastFetchTime: number | null;
	isChatListStale: boolean;
}

const initialChatState: ChatState = {
	chatList: [],
	currentThreadChat: [],
	input: "",
	isLoading: false,
	error: null,
	earningTranscriptSummary: null,
	lastFetchTime: null,
	isChatListStale: true,
};

export const clearAllChatData = createAsyncThunk("chat/clear-all-data", async (_, { dispatch }) => {
	try {
		// Clear all storage first
		clearAllStorage();

		// Reset Redux state
		dispatch(
			updateChatListWithTimestamp({
				chatList: [],
				timestamp: null,
			}),
		);
		dispatch(setCurrentThreadChat([]));
		dispatch(setInput(""));
		dispatch(setEarningTranscriptSummary(null));

		// Force a fresh fetch
		const response = await axios.get(services.CHAT_LIST);
		const chatList = response.data.data || [];

		dispatch(
			updateChatListWithTimestamp({
				chatList,
				timestamp: Date.now(),
			}),
		);

		return [];
	} catch (error) {
		console.error("Error clearing chat data:", error);
		// Even if fetch fails, ensure local state is cleared
		clearAllStorage();
		throw error;
	}
});

export const clearChatList = createAsyncThunk("chat/clear-list", async (_, { dispatch }) => {
	try {
		dispatch(
			updateChatListWithTimestamp({
				chatList: [],
				timestamp: Date.now(),
			}),
		);
		dispatch(setCurrentThreadChat([]));
		dispatch(setInput(""));
		dispatch(setEarningTranscriptSummary(null));
		return [];
	} catch (error) {
		console.error("Error clearing chat list:", error);
		throw error;
	}
});

export const checkAndUpdateChatList = createAsyncThunk(
	"chat/check-and-update",
	async (_, { getState, dispatch }) => {
		const state = getState() as { newChat: ChatState };
		const { lastFetchTime, isChatListStale } = state.newChat;
		const CACHE_DURATION = 5 * 60 * 1000;

		const shouldFetch =
			isChatListStale || !lastFetchTime || Date.now() - lastFetchTime > CACHE_DURATION;

		if (shouldFetch) {
			await dispatch(fetchAndUpdateChatList());
		}
	},
);

export const getApiKeyForChatSession = async() => {
	try {
		const response = await axios.get(services.CHAT_SESSION_API_KEY)
		const {api_key,tenant_id} = response.data;
		return {api_key,tenant_id};
	} catch (error) {
		console.log('error fetching api key : ', error)
		throw error
	}
}

export const createChatSession = async (userName: string): Promise<void> => {
	try {
		const {api_key,tenant_id} = await getApiKeyForChatSession()
		const response = await axios.post(
			`${services.CREATE_SESSION}/${userName}`,
			{},
			{
				withCredentials: true,
				headers: {
					"Content-Type": "application/json",
					"api-key": api_key,
					"tenant-id" : tenant_id
				},
			},
		);

		if (response.status !== 201) {
			throw new Error("Failed to create chat session");
		}
	} catch (err) {
		console.error("Error creating chat session:", err);
		throw err;
	}
};

export const initialiseChat = createAsyncThunk<void, { userName: string; symbol: string }>(
	"chat/initialise",
	async ({ userName, symbol }, { getState, dispatch }) => {
		try {
			// Step 1: Fetch updated chat list
			await dispatch(fetchAndUpdateChatList());

			const state = getState() as { newChat: ChatState };

			// Step 2: Check if there’s an existing chat session for this symbol
			const existingChat = state.newChat.chatList.find(
				(chat) => chat.metadata?.ticker_symbol === symbol,
			);

			if (existingChat) {
				try {
					// Step 3: Load existing chat messages instead of creating a new session
					const response = await axios.get<GetMessageResponse>(
						`${services.CHAT_MESSAGES}/${existingChat.chat_id}`,
					);

					if (response.data && response.data.data) {
						dispatch(setCurrentThreadChat(response.data.data));
						return;
					}
				} catch (error) {
					console.error("Error loading existing chat:", error);
				}
			}

			// Step 4: If no existing chat is found, create a new session
			await createChatSession(userName)

			// Step 5: Get transcript summary and create a new chat entry
			await dispatch(fetchEarningCallTranscriptSummary(symbol));

			const updatedState = getState() as { newChat: ChatState };
			const earningTranscriptSummary = updatedState.newChat.earningTranscriptSummary;

			if (!earningTranscriptSummary) {
				throw new Error("Failed to fetch transcript summary");
			}

			const { metadataFromSummary, summary, tickerSymbol } = earningTranscriptSummary;

			const chatHistoryPayload = {
				user_id: userName,
				user_name: userName,
				last_activity_time: Date.now().toString(),
				chat_date: new Date().toISOString().split("T")[0],
				metadata: metadataFromSummary as ChatMetadata,
			};

			//  Store new chat ID
			const { chat_id } = await dispatch(postChatHistory(chatHistoryPayload)).unwrap();

			//  Step 6: Initialize new chat with an initial message
			const initialMessage = {
				question: symbol,
				answer: summary,
				chat_id,
				creation_time: new Date().toISOString(),
			};

			dispatch(setCurrentThreadChat([initialMessage]));

			await dispatch(
				postChatMessage({
					chat_id,
					question: tickerSymbol,
					answer: summary,
					error: "",
				}),
			);

			await dispatch(fetchAndUpdateChatList());
		} catch (error) {
			console.error("Error in initialiseChat:", error);
			throw error;
		}
	},
);
export const fetchEarningCallTranscriptSummary = createAsyncThunk(
	"fetchEarningTransCript",
	async (
		symbol: string,
		{ dispatch },
	): Promise<{ metadataFromSummary: ChatMetadata; tickerSymbol: string; summary: string }> => {
		try {
			const response = await axios.get(`${services.AI_ASSISTANT}?ticker_symbol=${symbol}&doc_type=${"earnings-call-transcript"}`);
			if (response.data) {
				const { summary, metadata: metadataFromSummary } = response.data;
				const tickerSymbol = metadataFromSummary.ticker_symbol;
				dispatch(setEarningTranscriptSummary({ summary, metadataFromSummary, tickerSymbol }));
				dispatch(fetchAndUpdateChatList());

				return { summary, metadataFromSummary, tickerSymbol };
			}
			throw new Error("No data found");
		} catch (err) {
			console.error(err);
			throw err;
		}
	},
);

export const fetchAndUpdateChatList = createAsyncThunk(
	"chat/chat-list",
	async (_, { dispatch }) => {
		try {
			const response = await axios.get(services.CHAT_LIST);
			const data = response.data.data;

			let chatList: ChatList[] = [];

			if (data && Object.keys(data).length > 0 && Array.isArray(data)) {
				chatList = orderBy(data, ["last_activity_time"], ["desc"]);
				chatList = uniqBy(chatList, "chat_id");
			}
			/*********************************************************************************************************/
			/*******  leave below commented out code for debugging purpose *********/
			/*********************************************************************************************************/

			// const state = getState() as { newChat: ChatState };

			// Preserve updated `chat_date` if it's already set in Redux
			// const updatedChatList = chatList.map((serverChat) => {
			//  const localChat = state.newChat.chatList.find((c) => c.chat_id === serverChat.chat_id);
			//  return localChat ? { ...serverChat, chat_date: localChat.chat_date } : serverChat;
			// });

			/*********************************************************************************************************/

			dispatch(
				updateChatListWithTimestamp({
					chatList,
					timestamp: Date.now(),
				}),
			);

			return chatList;
		} catch (error) {
			dispatch(
				updateChatListWithTimestamp({
					chatList: [],
					timestamp: Date.now(),
				}),
			);
			throw error;
		}
	},
);

// post chat history to get chat_id for initial chat - atlantis
export const postChatHistory = createAsyncThunk<{ chat_id: string }, ChatHistoryPayload>(
	"chat/post-chat-history",
	async (chatHistoryPayload: ChatHistoryPayload, { rejectWithValue }) => {
		try {
			const response = await axios.post(services.CHAT_HISTORY, chatHistoryPayload);
			if (!response.data?.data.chat_id) {
				throw new Error("Invalid response: Missing chat_id");
			}
			return { chat_id: response.data.data.chat_id };
		} catch (error: any) {
			console.error("Error posting chat history:", error);
			return rejectWithValue(error.response?.data || "Failed to post chat history");
		}
	},
);

// post chat-message - It will add a new message to the chat thread - atlantis
export const postChatMessage = createAsyncThunk<PostMessageResponse, PostMessagePayload>(
	"chat/post-message",
	async ({ chat_id, question, answer, error = "" }, { rejectWithValue }) => {
		try {
			const response = await axios.post<PostMessageResponse>(
				`${services.CHAT_MESSAGES}/${chat_id}`,
				{
					chat_id,
					question,
					answer,
					error,
				},
			);
			return response.data;
		} catch (err: any) {
			console.error("Error posting message:", err);
			return rejectWithValue(
				err.response?.data || { message: "Failed to post message", api_status: "error" },
			);
		}
	},
);

export const getChatMessages = createAsyncThunk<GetMessageResponse, string>(
	"chat/get-message",
	async (chat_id, { dispatch, rejectWithValue }) => {
		try {
			const response = await axios.get<GetMessageResponse>(`${services.CHAT_MESSAGES}/${chat_id}`);
			return response.data;
		} catch (err: any) {
			console.error("Error fetching chat message:", err);
			return rejectWithValue(
				err.response?.data || { message: "Failed to fetch message", api_status: "error" },
			);
		}
	},
);

export const postThreadChatMessage = createAsyncThunk<
	ThreadChatResponseAlpine[],
	{ question: string; symbol: string }
>("chat/post-question", async ({ question, symbol }, { getState, dispatch, rejectWithValue }) => {
	let currentThreadChat: ChatQuestionAnswer[] = [];

	try {
		const state = getState() as { newChat: ChatState };
		currentThreadChat = state.newChat.currentThreadChat ? [...state.newChat.currentThreadChat] : [];
		const currentChatId = currentThreadChat.length > 0 ? currentThreadChat[0].chat_id : null;

		if (!currentChatId) {
			throw new Error("No chat session found");
		}

		const userState = (getState() as any).user;
		const userName = userState?.user?.username;

		if (!userName) {
			throw new Error("No user found");
		}

		const currentChat = state.newChat.chatList.find((chat) => chat.chat_id === currentChatId);
		if (!currentChat) {
			throw new Error("Chat not found");
		}

		const chatSymbol = currentChat.metadata?.ticker_symbol;
		if (!chatSymbol) {
			throw new Error("Symbol not found for chat");
		}

		await createChatSession(userName);

		const messageCreationTime = (Date.now() / 1000).toString();
		const placeholderMessage = {
			question,
			answer: "Give me a moment...",
			chat_id: currentChatId,
			creation_time: messageCreationTime,
		};

		const updatedThreadChat = [...currentThreadChat, placeholderMessage];

		dispatch(setCurrentThreadChat(updatedThreadChat));
		dispatch(setInput(""));

		// generate streaming response on UI.
		const response = await fetchStreamResponse(symbol, question,messageCreationTime,updatedThreadChat,dispatch)

		const latestState = getState() as { newChat: ChatState };
		const latestThreadChat = latestState.newChat.currentThreadChat || [];
		const latestMessage = latestThreadChat.at(-1) as ChatQuestionAnswer;

		await dispatch(postChatMessage({ ...latestMessage }))

		await axios.patch(`${services.CHAT_HISTORY}/${currentChatId}`, {
			chat_date: new Date().toISOString().split("T")[0],
			last_activity_time: Date.now().toString(),
		});

		await dispatch(fetchAndUpdateChatList());
		return response
	} catch (err: any) {
		console.error("Error in chat service:", err);
		return rejectWithValue(err.response?.data || "Failed to fetch chat response");
	}
});

const newChatSlice = createSlice({
	name: "chat",
	initialState: initialChatState,
	reducers: {
		updateChatListWithTimestamp(state, action) {
			state.chatList = action.payload.chatList;
			state.lastFetchTime = action.payload.timestamp;
			state.isChatListStale = false;
		},

		markChatListStale(state) {
			state.isChatListStale = true;
		},
		updateChatList(state, action) {
			state.chatList = action.payload.chatList;
		},
		setEarningTranscriptSummary(state, action) {
			state.earningTranscriptSummary = action.payload;
		},
		setCurrentThreadChat: (state, action) => {
			state.currentThreadChat = action.payload
		},
		setInput: (state, action) => {
			state.input = action.payload;
		},
		startNewChat: (state) => {
			// logic to be implemented..
		},
		loadChatSession: (state, action) => {
			// we can remove it as , we are handling this with current thread chat
			// load chat session on the basis of selected chat_id
		},

		clearAllChatData: (state) => {
			state.chatList = [];
			state.currentThreadChat = [];
			state.input = "";
			state.earningTranscriptSummary = null;
			state.lastFetchTime = null;
			state.isChatListStale = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAndUpdateChatList.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(fetchAndUpdateChatList.fulfilled, (state) => {
				state.isLoading = false;
				state.error = null;
			})
			.addCase(fetchAndUpdateChatList.rejected, (state, action) => {
				state.isLoading = false;
				state.error = action.error.message || "Failed to fetch chat list";
			});
	},
});

export const {
	updateChatListWithTimestamp,
	markChatListStale,
	updateChatList,
	setEarningTranscriptSummary,
	setInput,
	startNewChat,
	loadChatSession,
	setCurrentThreadChat,
} = newChatSlice.actions;

export default newChatSlice.reducer;
