import React from "react";
import "../../../components/Common/ComingSoon/ComingSoon.css";
import PageTitle from "../../../components/Common/PageTitle";
import { Box } from "@mantine/core";
import ComingSoon from "../../../components/Common/ComingSoon/ComingSoon";

const AdminDashboard: React.FC = () => {
	return (
		<Box>
			<PageTitle TITLE='Dashboard' />
			<ComingSoon />
		</Box>
	);
};

export default AdminDashboard;
