import React, { useMemo, useRef, useEffect } from "react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../Agtablehomepage/Agstyles.css";
import { Box } from "@mantine/core";
import { AllFundQueryTableProps } from "../../types";
import { AgGridEvent, } from "ag-grid-community";
import CustomTable from "../CustomTable/CustomTable";
import { applyAlignment, applyDecorators, applyHeaderAlignment, getTableData } from "../../utils/conversions";
import { t } from "i18next";

interface TableConfig {
	pinnedColumns?: string[];
	columnOrder?: string[];
	hideHeaders?: string[];
}
interface AllFundQueryTablePropsExtended extends AllFundQueryTableProps {
	isDisplayedFromHoldingContentTab?: boolean;
}

interface AllFundQueryTablePropsExtended extends AllFundQueryTableProps {
	isFromHoldingContentTab?: boolean;
	tableConfig?: TableConfig;
}

const AllFundQueryTable: React.FC<AllFundQueryTablePropsExtended> = ({
	data,
	isFromHoldingContentTab = true,
	tableConfig = {},
	selectedTypeOfData
}) => {
	const tableWrapperRef = useRef<HTMLDivElement>(null);

	const columnDefs = useMemo(() => {
		if (data.length === 0) return [];
		let typeOfData;
		switch (selectedTypeOfData) {
			case 'DATA_TYPE_ASSETS':
				typeOfData = 'detail_holdings';
				break;
			case 'DATA_TYPE_ORDERS':
				typeOfData = 'Orders';
				break;
			case 'DATA_TYPE_BENCHMARK_CHANGES':
				typeOfData = 'Index Change';
				break;
			case 'DATA_TYPE_TARGETS_AND_TOLERANCES':
				typeOfData = 'Targets & TOLs';
				break;
			case 'DATA_TYPE_TRADES':
				typeOfData = 'trades';
				break;
			case 'DATA_TYPE_CASH_FLOWS':
				typeOfData = 'Cash Flows';
				break;
			default:
				typeOfData = 'detail_holdings';
		}

		const tableData = getTableData(typeOfData);
		const columns = tableData.map(({ colHeader, key, decorators, alignment }) => ({
			headerName: t(colHeader),
			field: key,
			cellRenderer: ({ value }: { value: string }) => applyDecorators(value, decorators),
			cellClass: applyAlignment(alignment),
			headerClass: applyHeaderAlignment(alignment)
		}));


		return columns;


	}, [data, tableConfig, selectedTypeOfData]);



	useEffect(() => {
		if (tableWrapperRef.current) {
			const cells = tableWrapperRef.current.querySelectorAll(".ag-cell-value");
			let maxContentWidth = 0;
			cells.forEach((cell) => {
				maxContentWidth = Math.max(maxContentWidth, cell.clientWidth);
			});
			tableWrapperRef.current.style.minWidth = `${maxContentWidth}px`;
		}
	}, [data]);

	const maxHeight = isFromHoldingContentTab ? true : false;

	return (
		<Box
			ref={tableWrapperRef}
			w='100%'
			style={{ width: "100%", overflowX: "auto", overflowY: "hidden" }}
		>
			{data.length > 0 ? (
				<CustomTable
					rowData={data}
					columnDefs={columnDefs}
					fullLength={maxHeight ? true : false}
					onSortChanged={(e: AgGridEvent) => {
						e.api.refreshCells();
					}}
					className='fund-query'
				/>
			) : (
				<div> </div>
			)}
		</Box>
	);
};

export default AllFundQueryTable;
