import React, { ReactNode } from "react";

interface NavbarScrollAreaProps {
	children: ReactNode;
}

const NavbarScrollArea: React.FC<NavbarScrollAreaProps> = ({ children }) => {
	return <div className='scroll-bar'>{children}</div>;
};

export default NavbarScrollArea;
