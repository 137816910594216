import React, { useMemo, useState, useEffect, useRef } from "react";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import {
    Flex,
    Text,
    useMantineTheme,
    Button,
    Card,
    Modal,
    Grid,
    TextInput,
    Box,
} from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { services } from "../../../services";
import "./AdminPage.css";
import { FormattedUser, KeyValuePairs, UniqueDescription, UsersType } from "../../../types";
import PageTitle from "../../../components/Common/PageTitle";
import RolesTable from "../../../components/AdminPage/Roles";
import FundGroup from "../../../components/AdminPage/FundGroup/FundGroup";
import { useDisclosure } from "@mantine/hooks";
import { roles } from "../../../constants";
import AdminTableHeader from "../../../components/AdminPage/AdminTableHeader";
import CustomButton from "../../../components/Common/Buttons/CustomButton";
import { CustomSelectBox } from "../../../components/Common/Select/CustomSelectBox";
import { handleMainContentClickUtil } from "../../../utils/handleMainContentClick";
import { useDeselectAllOnRowUnselect } from "../../../utils/useDeselectAllOnRowUnselect";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { getTableData } from "../../../utils/conversions";
import { rowHeight, rowSelectionTypeSingle } from "../../../components/CustomTable/utilsAgTable";
import { DeleteModal } from "../../../components/Common/Modals/DeleteModal";

const Users = () => {
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme();
    const [userIdTest, setUserIdTest] = useState("");
    const [userId, setUserId] = useState<string | null>("");
    const [roleID, setRoleID] = useState<string>("");
    const [apiData, setApiData] = useState<any[]>([]);
    const [editMode, setEditMode] = useState(false);
    const [userToDelete, setUserToDelete] = useState<{
        id: string;
        username: string;
        roleID: string;
    } | null>(null);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [roleCounts, setRoleCounts] = useState<{ [key: string]: number }>({});
    const [fundGroupCounts, setfundGroupCounts] = useState<{ [key: string]: number }>({});
    const [roleAndFundGroupCounts, setRoleAndFundGroupCounts] = useState<any>({});
    const [modalType, setModalType] = useState<null | "create" | "update" | "delete">(null);
    const [modalOpened, { toggle: toggleModal }] = useDisclosure(false);
    const [uniqueDescriptions, setUniqueDescriptions] = useState<UniqueDescription[]>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const initialNewUserData = {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        enabled: true,
        credentials: [
            {
                type: "password",
                value: "test",
                temporary: false,
            },
        ],
        roles: {},
    };

    const [uniqueFundGroup, setUniqueFundGroup] = useState<string[]>([]);

    const optionsRole = (
        uniqueDescriptions.length > 0 ? uniqueDescriptions : [{ description: "", role: "" }]
    )
        .sort((a, b) => {
            const valA = a.description || "";
            const valB = b.description || "";
            return valA.localeCompare(valB);
        })
        .map((item) => ({
            value: item.role,
            label: item.description,
        }));

    const optionsFundGroup = (uniqueFundGroup.length > 0 ? uniqueFundGroup : [""])
        .sort((a, b) => {
            a = a || "";
            b = b || "";
            return a.localeCompare(b);
        })
        .map((fundGroup) => ({
            value: fundGroup,
            label: fundGroup,
        }));

    const [rolesLastUpdated, setRolesLastUpdated] = useState<number>(Date.now());

    const handleUniqueDescriptionsChange = (descriptions: any) => {
        setUniqueDescriptions(descriptions);
        setRolesLastUpdated(Date.now());
    };

    const handleUniqueFundGroupChange = (uniqueFundGroup: string[]) => {
        setUniqueFundGroup(uniqueFundGroup);
    };

    const handleModalToggle = (type: "create" | "update") => {
        if (modalType === type && modalOpened) {
            setModalType(null);
            toggleModal();
        } else {
            setModalType(type);
            if (!modalOpened) toggleModal();
        }
    };

    const [newUserData, setNewUserData] = useState(initialNewUserData);

    const [selectedRole, setSelectedRole] = useState("");
    const [selectedFundGroup, setSelectedFundGroup] = useState(""); // Initialize with 'All-Index' as default

    const handleRoleChange = (value: string | null) => {
        if (value) {
            setSelectedRole(value);
        }
    };

    const newFundGroupRole = {
        fund_group: " ",
        role: " ",
    };
    const [fundGroupRole, setFundGroupRole] = useState([newFundGroupRole]);

    const addNewFundGroupRole = () => {
        setFundGroupRole([...fundGroupRole, newFundGroupRole]);
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${services.GET_FUND_DETAILS}`);
            const data = response.data;
            // Map fetched data to match ag-grid format
            const formattedData: FormattedUser[] = Array.isArray(data)
                ? data.map((user: UsersType) => ({
                    user_id: user.username ?? "",
                    username: `${user.firstName ?? ""} ${user.lastName ?? ""}`,
                    email: user.email !== "null" ? user.email : "",
                    role: user.roleDescription ?? "",
                    fund_group: user.fund_group ?? "",
                    id: user.id ?? "",
                    role_id: user.user_id ?? "",
                }))
                : [];
            setApiData(formattedData);

            const fundGroupUserCounter: any = {};
            const fundGroupRoleCounter: any = {};
            const uniqueRolesCount: any = {};
            formattedData.forEach((record: any) => {
                const { fund_group, role } = record;
                fundGroupUserCounter[fund_group] = fundGroupUserCounter[fund_group]
                    ? fundGroupUserCounter[fund_group] + 1
                    : 1;
                if (!fundGroupRoleCounter[fund_group]) {
                    fundGroupRoleCounter[fund_group] = new Set();
                }
                fundGroupRoleCounter[fund_group].add(role);
                uniqueRolesCount[role] = uniqueRolesCount[role] ? uniqueRolesCount[role] + 1 : 1;
            });

            setRoleCounts(uniqueRolesCount);
            setfundGroupCounts(fundGroupUserCounter);
            setRoleAndFundGroupCounts(fundGroupRoleCounter);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchData();
        }, 500);
        return () => clearTimeout(timer);
    }, [editMode, userIdTest, rolesLastUpdated]);

    const columnDefs: ColDef[] = useMemo(() => {
        const tableData = getTableData("USERS");
        const columns = [
            ...tableData.map(({ colHeader, key, decorators }) => ({
                headerName: t(colHeader),
                field: key,
                cellRenderer: null,
                flex: 1,
            })),
            {
                headerName: t("ROLE"),
                field: "role",
                width: 250,
                cellRenderer: (params: ValueGetterParams) => {
                    if (Array.isArray(params.data.role)) {
                        const roles = params.data.role.join(", ");
                        return <div>{t(roles)}</div>;
                    } else {
                        return <div>{t(params.data.role)}</div>;
                    }
                },
            },
            {
                headerName: t("FUND_GROUP"),
                field: "fund_group",
                flex: 1,
                width: 305,
                cellRenderer: (params: ValueGetterParams) => {
                    if (Array.isArray(params.data.fund_group)) {
                        const fundGroups = params.data.fund_group.join(", ");
                        return <div>{fundGroups}</div>;
                    } else {
                        return <div>{params.data.fund_group}</div>;
                    }
                },
            },
        ];
        return columns;
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewUserData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSelectionChanged = (event: any) => {
        setIsRowSelected(event.api.getSelectedNodes().length > 0);
    };

    const handleDeleteUser = () => {
        if (userToDelete) {
            axios
                .delete(services.UPDATE_DELETE_USER(userToDelete.roleID), { data: { id: userToDelete.id } })
                .then((response) => {
                    axios
                        .get(`${services.GET_FUND_DETAILS}`)
                        .then((response) => {
                            const data = response.data;
                            const formattedData = data.map((user: any) => ({
                                user_id: user.username,
                                username: user.firstName + " " + user.lastName,
                                email: user.email,
                                role: user.role,
                                fund_group: user.fund_group,
                                id: user.id,
                            }));
                            setApiData(formattedData);
                            setTimeout(() => {
                                fetchData();
                                setIsRowSelected(false);
                            }, 500);
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error("Error deleting user role:", error);
                })
                .finally(() => {
                    setIsDeleteModalOpen(false);
                });
        }
    };

    const handleDeleteClick = () => {
        const gridApi = gridApiRef.current;
        const selectedNodes = gridApi.getSelectedNodes();

        if (selectedNodes.length > 0) {
            const selectedNode = selectedNodes[0];
            const userId = selectedNode.data.id;
            const username = selectedNode.data.username;
            const roleID = selectedNode.data.role_id;

            setUserToDelete({ id: userId, username: username, roleID: roleID });
            setIsDeleteModalOpen(true);
        }
    };

    const handleFundGroupChange = (value: string | null) => {
        setSelectedFundGroup(value || "");

        setNewUserData((prevState) => ({
            ...prevState,
            roles: {
                ...prevState.roles,
                [selectedRole]: value ? [value] : [],
            },
        }));
    };

    const FundGroupRole = ({ }: { index: number; item: KeyValuePairs }) => {
        return (
            <Flex direction={"row"} mt={"md"}>
                <Flex>
                    <CustomSelectBox
                        value={selectedFundGroup}
                        onChange={handleFundGroupChange}
                        data={optionsFundGroup}
                        label={t("FUND_GROUP")}
                        className='textInputRole'
                    />
                </Flex>

                <Flex ml={"xl"} justify={"center"} align={"center"}>
                    <CustomSelectBox
                        value={selectedRole}
                        onChange={handleRoleChange}
                        data={optionsRole}
                        label={t("ROLE")}
                        className='textInputRole'
                        ml={"xxxl"}
                        mr={"-0.2rem"}
                    />
                </Flex>
            </Flex>
        );
    };

    const handleEditUser = () => {
        const gridApi = gridApiRef.current;
        console.log("gridApi", gridApi);
        const selectedNodes = gridApi.getSelectedNodes();

        if (selectedNodes.length > 0) {
            const selectedNode = selectedNodes[0];
            const selectedUser = selectedNode.data;
            console.log({ selectedUser });

            setNewUserData({
                username: selectedUser.user_id,
                email: selectedUser.email,
                firstName: selectedUser.username.split(" ")[0],
                lastName: selectedUser.username.split(" ")[1],
                enabled: true,
                credentials: [
                    {
                        type: "password",
                        value: "test",
                        temporary: false,
                    },
                ],
                roles: {
                    [selectedUser.role]: selectedUser.fund_group[0],
                },
            });
            handleRoleChange(roles.find((row) => row.label === selectedUser.role)?.key || null);
            setSelectedFundGroup(selectedUser.fund_group[0]);
            setUserId(selectedUser.id);
            setRoleID(selectedUser.role_id);
            setEditMode(true);
            handleModalToggle("update");
        }
    };

    const handleCreateUser = async () => {
        const rolesObject = {
            [selectedRole]: selectedFundGroup ? [selectedFundGroup] : [],
        };

        const newUser = {
            ...newUserData,
            roles: rolesObject,
        };

        if (editMode) {
            console.log({ selectedFundGroup });

            axios
                .put(`${services.UPDATE_DELETE_USER(roleID)}`, {
                    id: userId,
                    role: selectedRole,
                    fundGroups: selectedFundGroup ? [selectedFundGroup] : [],
                })
                .catch((error) => {
                    console.error("Error updating user:", error);
                });
            handleModalToggle("update");
            setTimeout(() => {
                fetchData();
                setIsRowSelected(false);
            }, 500);
        } else {
            axios
                .post(`${services.CREATE_USER}`, newUser)
                .then((response) => {
                    const createdUser = response.data;
                    setApiData([...apiData, createdUser]);
                    handleModalToggle("create");
                    setTimeout(() => {
                        fetchData();
                    }, 500);
                })
                .catch((error) => {
                    console.error("Error creating user:", error);
                });
        }
    };

    const gridApiRef = useRef<any>(null);
    useDeselectAllOnRowUnselect(gridApiRef, isRowSelected);

    const onGridReady = (params: { api: any }) => {
        gridApiRef.current = params.api;
    };

    const resetCreateUser = () => {
        setEditMode(false);
        handleModalToggle("create");
        setNewUserData(initialNewUserData);
        setSelectedFundGroup("");
        setSelectedRole("");
    };

    const refreshFundGroupRole = () => {
        setFundGroupRole([...fundGroupRole]);
    };

    useEffect(() => {
        refreshFundGroupRole();
    }, []);

    const mainContentRef = useRef<HTMLDivElement>(null);

    const handleMainContentClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        handleMainContentClickUtil(e, mainContentRef, setIsRowSelected);
    };

    return (
        <>
            <PageTitle TITLE='Users' />
            <Box
                className='main-content-users'
                ref={mainContentRef}
                onClick={handleMainContentClick}
                pl={"1.8rem"}
                pr={"2rem"}
                pt={"1.1rem"}
            >
                <Modal
                    opened={modalOpened && modalType === (editMode ? "update" : "create")}
                    onClose={() => handleModalToggle(editMode ? "update" : "create")}
                    size='45.4375rem'
                    centered
                    withCloseButton={false}
                    radius={"1.875rem"}
                    className='modal'
                >
                    <Card bg={theme.colors.grey[2]} pt={"xs"} pb={"xl"} pl={"2.8rem"} pr={"2.5rem"}>
                        <Text fw={700} size='lg' ta={"center"} mb={theme.spacing.xs}>
                            {" "}
                            {editMode ? t("EDIT_USER_ACCOUNT") : t("CREATE_USER_NEW_ACCOUNT")}
                        </Text>
                        <Grid mt={"lg"}>
                            <Grid.Col span={10}>
                                <TextInput
                                    type='text'
                                    name='username'
                                    w={"33.625rem"}
                                    label={t("USER_ID")}
                                    value={newUserData.username}
                                    onChange={handleInputChange}
                                    className='textInput'
                                    disabled={editMode}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid mt={"1.2rem"}>
                            <Grid.Col span={10}>
                                <TextInput
                                    type='email'
                                    name='email'
                                    w={"33.625rem"}
                                    label={t("EMAIL")}
                                    value={newUserData.email}
                                    onChange={handleInputChange}
                                    className='textInput'
                                    disabled={editMode}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid mt={"1.1rem"}>
                            <Grid.Col span={10}>
                                <TextInput
                                    type='text'
                                    name='firstName'
                                    w={"33.625rem"}
                                    label={t("FIRST_NAME")}
                                    value={newUserData.firstName}
                                    onChange={handleInputChange}
                                    className='textInput'
                                    disabled={editMode}
                                />
                            </Grid.Col>
                        </Grid>

                        <Grid mt={"1.4rem"}>
                            <Grid.Col span={10}>
                                <TextInput
                                    type='text'
                                    name='lastName'
                                    w={"33.625rem"}
                                    label={t("LAST_NAME")}
                                    value={newUserData.lastName}
                                    onChange={handleInputChange}
                                    className='textInput'
                                    disabled={editMode}
                                />
                            </Grid.Col>
                        </Grid>

                        {fundGroupRole.map((item, idx) => (
                            <FundGroupRole key={idx} index={idx} item={item} />
                        ))}

                        <Button
                            variant='transparent'
                            mt={"lg"}
                            c='dark'
                            p={0}
                            ml={"-0.2rem"}
                            onClick={addNewFundGroupRole}
                            justify='flex-start'
                        >
                            <IconCirclePlus stroke={1.5} size={"1.2rem"} />
                            <Text ms='sm' size='md'>
                                {t("ADD_GROUPS_ROLES")}
                            </Text>
                        </Button>

                        <Flex justify='flex-end' pb={"1.8rem"} mt={"sm"} mr={"-0.4rem"}>
                            <CustomButton
                                variant='cancel'
                                onClick={() => {
                                    handleModalToggle(editMode ? "update" : "create");
                                    setFundGroupRole([newFundGroupRole]);
                                }}
                                children={"CANCEL"}
                            />
                            <CustomButton
                                variant='submit'
                                onClick={handleCreateUser}
                                children={editMode ? "SET" : "CREATE"}
                            />
                        </Flex>
                    </Card>
                </Modal>

                <DeleteModal
                    opened={isDeleteModalOpen}
                    onClose={() => setIsDeleteModalOpen(false)}
                    TITLE='DELETE_USER_ACCOUNT'
                    message='CONFIRM_DELETE_MESSAGE'
                    onSubmit={handleDeleteUser}
                />

                <Box>
                    <AdminTableHeader
                        isRowSelected={isRowSelected}
                        handleEditUser={handleEditUser}
                        handleDeleteClick={handleDeleteClick}
                        resetCreateUser={resetCreateUser}
                        headerTitle='ACCOUNTS'
                        createTitle='CREATE_USER_ACCOUNT'
                    />
                </Box>

                <CustomTable
                    rowData={apiData}
                    columnDefs={columnDefs}
                    rowSelection={rowSelectionTypeSingle}
                    onGridReady={onGridReady}
                    onSelectionChanged={handleSelectionChanged}
                    rowHeight={rowHeight}
                    className='action-item'
                    emptyMsg='NO_USERS_HERE'
                />

                <Box maw={"48rem"}>
                    <RolesTable
                        apiData={apiData}
                        roleCounts={roleCounts}
                        onUniqueDescriptionsChange={handleUniqueDescriptionsChange}
                    />
                    <Text id='selectedRows'></Text>

                    <FundGroup
                        roleCounts={roleCounts}
                        fundGroupCounts={fundGroupCounts}
                        roleAndFundGroupCounts={roleAndFundGroupCounts}
                        onUniqueFundGroup={handleUniqueFundGroupChange}
                    />
                </Box>
            </Box>
        </>
    );
};

export default Users;
