
import axios from "axios";
import { clearAllStorage } from "../utils/storageUtils";

axios.defaults.baseURL = "/";

axios.interceptors.request.use(
	async (config) => {
		if (config.url === "/auth/token") {
			return config;
		}
		return config;
	},
	(error) => Promise.reject(error.response),
);

axios.interceptors.response.use(
	(response) => response,
	async (error) => {
		return Promise.reject(error);
	},
);

export default axios;
