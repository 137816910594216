import React, { useContext } from "react";
import { Box, NavLink, useMantineTheme } from "@mantine/core";
import { UserOnNavLink } from "./UserOnNavLink";
import "./AccountInfo.css";
import { useTranslation } from "react-i18next";
import { useLogout } from "../../utils/handleLogOut";
import { KeycloakContext } from "../../Keycloak/KeycloakProvider";

const data = [
	{ key: "FUND" },
	{ key: "TABLE" },
	{ key: "ACCOUNT" },
	/********   Temp Commenting, Dont delete **********/
	// { key: "SYSTEM" },
	{ key: "LOG_OUT" },
];

interface SidebarProps {
	activeIndex: number;
	setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
	user: {
		fullName?: string;
		username?: string;
	} | null;
}

export const Sidebar: React.FC<SidebarProps> = ({ activeIndex, setActiveIndex, user }) => {
	const { t, i18n } = useTranslation();
	const theme = useMantineTheme();

	const handleLogout = useLogout();

	if (!i18n.isInitialized) {
		return null;
	}

	const items = data.map((item, index) => (
		<NavLink
			href={`account#${item.key}`}
			key={item.key}
			active={index === activeIndex}
			label={t(item.key)}
			onClick={() => {
				if (item.key === "LOG_OUT") {
					handleLogout();
				} else {
					setActiveIndex(index);
				}
			}}
			color={theme.colors.grey[9]}
			variant='filled'
			className={`navlink-account ${
				item.key === "LOG_OUT" ? "margin-log-out-navlink" : "margin-default-navlink"
			}`}
		/>
	));

	return (
		<Box w={257} p='0.30rem 0.47rem 0.47rem 0.47rem' bg={theme.colors.grey[10]}>
			<Box mb='md' pl={"0.4rem"}>
				{user && (
					<UserOnNavLink
						user={{
							fullName: user.fullName,
							username: user.username,
							companyName: t(""),
						}}
					/>
				)}
			</Box>
			{items}
		</Box>
	);
};
