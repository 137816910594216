import React, { useLayoutEffect, useRef } from "react";
import { Box } from "@mantine/core";
import { ChatBubble } from "./ChatBubble";
import { ChatTextInput } from "./ChatTextInput";
import { ChatQuestionAnswer } from "../../store/features/chat/types";

interface ChatContentProps {
	messages: ChatQuestionAnswer[];
	input: string;
	setInput: (value: string) => void;
	handleKeyPress: (event: React.KeyboardEvent) => void;
	onSendMessage?: () => void;
	isLoading?: boolean;
}

export const ChatContent: React.FC<ChatContentProps> = ({
	messages = [], 
	input,
	setInput,
	handleKeyPress,
	onSendMessage,
	isLoading = false,
}) => {
	const messagesEndRef = useRef<HTMLDivElement | null>(null); 
	const chatContainerRef = useRef<HTMLDivElement | null>(null); 

	useLayoutEffect(() => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [messages]); 
	if (!Array.isArray(messages)) {
		console.warn("Messages prop is not an array:", messages);
		return null; 
	}
	return (
		<Box className='chat-screen'>
			<Box
				className='chat-container'
				ref={chatContainerRef}
				style={{ overflowY: "auto", maxHeight: "80vh" }} 
			>
				{messages.map((message, index) => (
					<ChatBubble key={message.chat_id} message={message} />
				))}
				<div ref={messagesEndRef} />
			</Box>
			<Box className='chat-scroll-screen'>
				<ChatTextInput
					input={input}
					setInput={setInput}
					handleKeyPress={handleKeyPress}
					onSendMessage={onSendMessage}
					disabled={isLoading}
				/>
			</Box>
		</Box>
	);
};
