import React from "react";
import { Grid } from "@mantine/core";
import SidebarItem from "../Leftnavbar/SidebarItem";
import styles from "./AIAssistant.module.css";
import { t } from "i18next";

interface AIChatNavControlsProps {
	selectedItem: string;
	onNavItemClick: (label: string) => void;
}

export const AIChatNavControls: React.FC<AIChatNavControlsProps> = ({
  selectedItem,
  onNavItemClick,
}) => {

  const NAV_ITEMS = [
    { label: t("NEW_CHAT"), id: "new chat", icon: "new-chat-icon",path:"/ai-assistant"},
    { label: t("EXPLORE"), id: "explore", icon: "explore-icon 2",path:"/ai-assistant" }, 
  ];

  return (
    <Grid.Col className={styles.navigationSection}>
      {NAV_ITEMS.map(({ label, id, icon ,path}) => (
        <SidebarItem
          key={id}
          icon={icon}
          label={label}
          path={path}
          isSelected={selectedItem === id}
          onClick={() => {
            onNavItemClick(id); 
          }}
        />
      ))}
    </Grid.Col>
  );
};
