import React from "react";
import { Box } from "@mantine/core";
import IconWrapper from "../../Common/IconWrapper";

interface NavbarHeaderProps {
	className?: string;
}

const NavbarHeader: React.FC<NavbarHeaderProps> = ({ className = "left-nav-header" }) => {
	return (
		<Box className={className}>
			<IconWrapper name='Logo-long' className='nav-logo' alt='atlantis-logo' />
		</Box>
	);
};

export default NavbarHeader;
