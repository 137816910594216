import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextInput } from '@mantine/core';
import { t } from 'i18next';
import { InputError } from './Common/Errors/InputError';
import { services } from '../services';

interface AutocompleteProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  className?: string;
  multiSelect?: boolean;
}

const currencyField = ['CURRENCY', 'FUND_CURRENCY'];
const countryField = ['COUNTRY', 'ISSUE_COUNTRY', 'TRADING_COUNTRY', 'RISK_COUNTRY', 'LEGAL_JURISDICTION', 'FUND_DOMICILE'];
const fundNameField = ['FUND_NAME', 'RELATED_FUND', 'PRIMARY_FUND_MANAGER'];
const fundIDField = ['FUND_ID'];
const userIdField = ['PRIMARY_FUND_MANAGER', 'SECONDARY_FUND_MANAGER'];

export const AutoSuggestion: React.FC<AutocompleteProps> = ({
  label,
  value,
  onChange,
  onFocus,
  errorMessage,
  className,
  multiSelect = false,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [hint, setHint] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [activeOptionIndex, setActiveOptionIndex] = useState<number>(-1);
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(services.COUNTRIES);
        const { countries, currencies } = response.data.data as {
          countries: { country?: string; currency?: string }[];
          currencies: { currency: string }[];
        };

        if (currencyField.includes(label)) {
          setOptions(Array.from(new Set(currencies.map((c) => c.currency))).sort());
        } else if (countryField.includes(label)) {
          setOptions(
            Array.from(new Set(countries.map((c) => c.country).filter((value): value is string => !!value))).sort()
          );
        }

        if (fundNameField.includes(label)) {
          const response = await axios.get(services.FUND_NAMES);
          setOptions(response.data.data.fundNames.map((fund: { fundName: string }) => fund.fundName).sort());
        }
        if (fundIDField.includes(label)) {
          const response = await axios.get(services.FUND_NAMES);
          setOptions(response.data.data.fundNames.map((fund: { fundId: string }) => fund.fundId).sort());
        }

        if (userIdField.includes(label)) {
          const response = await axios.get(`${services.GET_FUND_DETAILS}`);
          setOptions(response.data.map((user: { username: string }) => user.username));
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    };

    fetchSuggestions();
  }, [label]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const lastEntry = multiSelect ? inputValue.split(',').pop()?.trim() || '' : inputValue;
    
    onChange(inputValue);
    const matches = options.filter((option) => option.toLowerCase().startsWith(lastEntry.toLowerCase()));
    
    setFilteredOptions(matches);
    setHint(matches.length > 0 ? matches[0] : '');
    setActiveOptionIndex(-1);
    setShowDropdown(matches.length > 0);
  };

  const handleOptionClick = (option: string) => {
    const valueStr = typeof value === "string" ? value : ""; 
  
    if (multiSelect) {
      const selectedValues = valueStr ? valueStr.split(",").map((v) => v.trim()) : [];
      if (!selectedValues.includes(option)) {
        const newValue = [...selectedValues, option].join(", ");
        onChange(newValue);
      }
    } else {
      onChange(option);
    }
  
    setFilteredOptions([]);
    setHint("");
    setShowDropdown(false);
    setActiveOptionIndex(-1);
  };
  

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' && hint) {
      e.preventDefault();
      handleOptionClick(hint);
    } else if (e.key === 'ArrowDown') {
      setActiveOptionIndex((prevIndex) => {
        const nextIndex = prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0;
        setHint(filteredOptions[nextIndex]);
        return nextIndex;
      });
    } else if (e.key === 'ArrowUp') {
      setActiveOptionIndex((prevIndex) => {
        const prevIndexValue = prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1;
        setHint(filteredOptions[prevIndexValue]);
        return prevIndexValue;
      });
    } else if (e.key === 'Enter' && activeOptionIndex !== -1) {
      handleOptionClick(filteredOptions[activeOptionIndex]);
    }
  };

  const handleBlur = (val: string) => {
    setShowDropdown(false);
    if (!multiSelect) {
      isValidOptionChosen(val);
    }
  };

  const isValidOptionChosen = (inputValue: string) => {
    const isValidInput = options.find((eachOption) => eachOption === inputValue);
    if (!isValidInput) {
      onChange('');
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <TextInput
          type="text"
          label={t(label)}
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={(e) => {
            setShowDropdown(filteredOptions.length > 0);
            onFocus && onFocus(e);
          }}
          onBlur={(e) => handleBlur(e.currentTarget.value)}
          className={className}
        />
        {hint && value && <span className="hint-input">{hint}</span>}
      </div>

      {showDropdown && (
        <ul className="hint-options">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              className="option-color"
              style={{
                backgroundColor: index === activeOptionIndex ? '#e6f7ff' : index % 2 === 0 ? '#f9f9f9' : '#fff',
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
      {errorMessage && <InputError message={errorMessage} />}
    </div>
  );
};

export default AutoSuggestion;
