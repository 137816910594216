import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { filterOrders, TabTypes } from "../../utils/filterOrders";
import { useFunds } from "../../utils/hooks/useFunds";
import { useOrders } from "../../utils/hooks/useOrders";
import PageTitle from "../../components/Common/PageTitle";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import SegmentedControl from "../AlgoJournal/SegmentedControl";
import { ORDER_POLLING_INTERVAL, useInterval } from "../../utils/hooks/useInterval";
import FundsTab from "../../components/AllFunds/FundsTab";
import OrdersTab from "../../components/AllFunds/OrdersTab";
import { setMarketState, setFundsOrdersTab, allFundsCounter } from "../../store/fundStatusSlice";

export const AllFundStatus = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectedMarketState, fundsOrdersTab } = useAppSelector((state) => state.fundStatus);
	const [selectedOrdersTab, setSelectedOrdersTab] = useState<TabTypes>("ALL");
	const segmentRefs = [useRef<HTMLDivElement>(null), useRef<HTMLDivElement>(null)];
	const controlRef = useRef<HTMLDivElement>(null);
	const { fundsData, fundsError, fetchDataForTable } = useFunds(selectedMarketState);
	const fundGroupData = JSON.parse(localStorage.getItem("fundGroup") || "[]");
	const fundGroup = fundGroupData.length > 0 ? fundGroupData[0].fundGroup : null;
	const { ordersData, ordersError, fetchOrders } = useOrders(fundGroup);
	const { allFundsMarketOnOpenCount, allFundsMarketOnCloseCount } = useAppSelector((state) => state.fundStatus)

	const filteredOrders = useMemo(() => {
		return filterOrders(ordersData, selectedOrdersTab);
	}, [ordersData, selectedOrdersTab]);

	const tabMessages = useMemo(
		() => ({
			MARKET_ON_OPEN: "Market on Open funds",
			MARKET: "Market funds",
			MARKET_ON_CLOSE: "Market on Close funds",
			ALL: "all funds",
		}),
		[],
	);

	const stableFetchOrders = useCallback(() => {
		fetchOrders();
	}, []);

	useEffect(() => {
		if (fundsOrdersTab === "ORDERS") {
			stableFetchOrders();
			const intervalId = setInterval(stableFetchOrders, ORDER_POLLING_INTERVAL);

			return () => clearInterval(intervalId);
		}
	}, [fundsOrdersTab, stableFetchOrders]);
	useInterval({
		callback: () => dispatch(allFundsCounter()),
		type: "thunk",
	});

	const handleMarketStateChange = (marketState: string) => {
		dispatch(
			setMarketState({
				marketState,
			}),
		);
	};

	const allfundlabel = t("ALL_FUND_STATUS");

	return (
		<>
			<PageTitle TITLE={allfundlabel} onMarketStateChange={handleMarketStateChange} />
			<Box>
				<div className='main-content'>
					<div className='margin-segment fund-tabs'>
						<SegmentedControl
							name='funds-orders-tabs'
							callback={(value) => {
								dispatch(setFundsOrdersTab(value as "FUNDS" | "ORDERS"));
							}}
							defaultIndex={fundsOrdersTab === "ORDERS" ? 1 : 0}
							controlRef={controlRef}
							segments={[
								{ label: t("FUNDS"), value: "FUNDS", ref: segmentRefs[0] },
								{ label: t("ORDERS"), value: "ORDERS", ref: segmentRefs[1] },
							]}
						/>
					</div>

					{fundsOrdersTab === "FUNDS" ? (
						<FundsTab
							t={t}
							selectedMarketState={selectedMarketState}
							setSelectedMarketState={(marketState) => dispatch(setMarketState({ marketState }))}
							computedCounts={{
								marketOnOpenCount: allFundsMarketOnOpenCount,
								marketOnCloseCount: allFundsMarketOnCloseCount,
							}}
							fundsData={fundsData}
							fundsError={fundsError}
							fetchDataForTable={fetchDataForTable}
						/>
					) : (
						<OrdersTab
							t={t}
							selectedOrdersTab={selectedOrdersTab}
							setSelectedOrdersTab={setSelectedOrdersTab}
							ordersData={ordersData}
							filteredOrders={filteredOrders}
							ordersError={ordersError}
							tabMessages={tabMessages}
						/>
					)}
				</div>
			</Box>
		</>
	);
};
