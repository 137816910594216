import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { services } from '../services';

interface FundState {
  isFundLocked: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: FundState = {
  isFundLocked: false,
  loading: false,
  error: null,
};

// Async thunk to fetch data
export const fetchFunds = createAsyncThunk(
    'funds/fetchFunds',
    async (
      params: { target_mode: string; fund_state: string; target_date: string; calc_date: string; fund_id: string },
      { rejectWithValue }
    ) => {
      try {
  
        const response = await axios.get(`${services.FUND_STATE}`, {
          params: {
            market_state: params.target_mode,
            fund_state: `FUND_STATE_${params.fund_state}`,
            target_date: params.target_date,
            calc_date: params.calc_date,
          },
        });
        const fundData = response.data.data.find((fund: any) => fund.fund_id === params.fund_id);
        return fundData?.is_fund_locked ?? false;
      } catch (error: any) {
        console.error('Error fetching funds:', error.response?.data || error.message);
        return rejectWithValue(error.response?.data?.message || 'Failed to fetch funds');
      }
    }
  );
  

const fundsSlice = createSlice({
  name: 'funds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFunds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFunds.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.isFundLocked = action.payload;
        state.loading = false;
      })
      .addCase(fetchFunds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default fundsSlice.reducer;
