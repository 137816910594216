import React, { ReactNode } from "react";

interface NavbarSectionProps {
	className?: string;
	children: ReactNode;
	style?: React.CSSProperties;
}

const NavbarSection: React.FC<NavbarSectionProps> = ({
	className = "tables-nav nav-section-mt nav-item",
	children,
}) => {
	return <div className={className}>{children}</div>;
};

export default NavbarSection;
