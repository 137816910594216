import React, { createContext, useState, useEffect, ReactNode } from "react";

interface OriginContextType {
	origin: string | null;
}

export const OriginContext = createContext<OriginContextType>({ origin: null });

interface OriginProviderProps {
	children: ReactNode;
}

export function OriginProvider({ children }: OriginProviderProps) {
	const [origin, setOrigin] = useState<string | null>(null);

	useEffect(() => {
		const storedOrigin = localStorage.getItem("origin");

		if (storedOrigin) {
			setOrigin(storedOrigin);
		} else if (typeof window !== "undefined") {
			const currentOrigin = window.location.origin;
			setOrigin(currentOrigin);
			localStorage.setItem("origin", currentOrigin);
		}
	}, []);

	if (!origin) {
		return null;
	}

	return <OriginContext.Provider value={{ origin }}>{children}</OriginContext.Provider>;
}
