import React, { createContext, useContext, ReactNode, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
	setInput,
	postThreadChatMessage,
	getChatMessages,
	setCurrentThreadChat,
	initialiseChat,
	createChatSession,
} from "../store/features/chat/newChatSlice";
import { useNavigate } from "react-router-dom";

interface ChatContextType {
	selectedItem: string;
	isLeftNavOpen: boolean;
	setSelectedItem: (item: string) => void;
	toggleSidebar: () => void;
	handleSendMessage: () => Promise<void>;
	handleKeyPress: (event: React.KeyboardEvent) => void;
	handleSetInput: (value: string) => void;
	handleStartNewChat: () => void;
	handleLoadChatSession: (sessionId: string) => void;
}

export const ChatContext = createContext<ChatContextType | undefined>(undefined);

interface ChatProviderProps {
	children: ReactNode;
	selectedItem: string;
	setSelectedItem: (item: string) => void;
	isLeftNavOpen: boolean;
	setIsLeftNavOpen: (value: boolean) => void;
	input: string;
	symbol?: string;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({
	children,
	selectedItem,
	setSelectedItem,
	isLeftNavOpen,
	setIsLeftNavOpen,
	input,
	symbol,
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { chatList } = useAppSelector((state) => state.newChat);
	const { user } = useAppSelector((state) => state.user);

	const toggleSidebar = useCallback(() => {
		setIsLeftNavOpen(!isLeftNavOpen);
	}, [setIsLeftNavOpen, isLeftNavOpen]);

	const handleSendMessage = useCallback(async () => {
		if (input.trim() && symbol) {
			try {
				await dispatch(postThreadChatMessage({ question: input, symbol: symbol })).unwrap();
			} catch (err) {
				console.error("Error sending message:", err);
			}
		}
	}, [dispatch, input, symbol]);

	const handleKeyPress = useCallback(
		(event: React.KeyboardEvent) => {
			if (event.key === "Enter" && !event.shiftKey) {
				event.preventDefault();
				handleSendMessage();
			}
		},
		[handleSendMessage],
	);

	const handleSetInput = useCallback(
		(value: string) => {
			dispatch(setInput(value));
		},
		[dispatch],
	);

	const handleStartNewChat = useCallback(() => {
		if(user)createChatSession(user.username)
		dispatch(setCurrentThreadChat([]));
	}, [dispatch]);

	const handleLoadChatSession = useCallback(
		async (sessionId: string) => {
			try {
				const chat = chatList.find((c) => c.chat_id === sessionId);
				if (!chat) return;

				if (chat?.metadata?.ticker_symbol) {
					// Fetch messages WITHOUT modifying chat_list
					const response = await dispatch(getChatMessages(sessionId)).unwrap();

					if (response.data && response.data.length > 0) {
						dispatch(setCurrentThreadChat(response.data));
					}
					// Do NOT initialize chat unless it's completely empty
					else {
						const hasExistingMessages = response.data.length > 0;
						if (!hasExistingMessages) {
							await dispatch(
								initialiseChat({
									userName: user?.username || "",
									symbol: chat.metadata.ticker_symbol,
								}),
							);
						}
					}
				}
			} catch (error) {
				console.error("Error in handleLoadChatSession:", error);
			}
		},
		[dispatch, chatList, user],
	);
	const value = {
		selectedItem,
		isLeftNavOpen,
		setSelectedItem,
		toggleSidebar,
		handleSendMessage,
		handleKeyPress,
		handleSetInput,
		handleStartNewChat,
		handleLoadChatSession,
	};

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => {
	const context = useContext(ChatContext);
	if (!context) {
		throw new Error("Error");
	}
	return context;
};
