import React, { useRef } from "react";
import { Box, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Location } from "react-router-dom";
import { routes } from "../../routes";
import IconWrapper from "../Common/IconWrapper";
import { useLogout } from "../../utils/handleLogOut";
import NavbarContainer from "./shared/NavbarContainer";
import NavbarHeader from "./shared/NavbarHeader";
import NavbarScrollArea from "./shared/NavbarScrollArea";
import NavbarSection from "./shared/NavbarSection";
import SidebarItem from "./SidebarItem";

const isPathSelected = (routerLocation: Location, path: string): boolean => {
	return routerLocation.pathname === path;
};
const AdminNavbar: React.FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const paperRef = useRef<HTMLDivElement>(null);
	const handleLogout = useLogout();

	const location = useLocation();
	const navItems = [
		{ icon: "dashboard", label: t("DASHBOARD"), path: routes.COMINGDASHBOARD },
		{ icon: "usersAdmin", label: t("USERS"), path: routes.USERS },
		{ icon: "funds", label: t("INVESTMENTS"), path: routes.INVESTMENTS },
		{ icon: "preferences", label: t("PREFERENCES"), path: routes.COMINGPREFERENCE },
		{ icon: "softwareUpdates", label: t("SOFTWARE_UPDATES"), path: routes.COMINGUPDATES },
		{ icon: "support", label: t("CUSTOMER_SUPPORT"), path: routes.COMINGSUPPORT },
	];

	const handleItemClick = (path: string) => {
		navigate(path);
	};

	return (
		<NavbarContainer paperRef={paperRef}>
			<NavbarHeader />

			<NavbarScrollArea>
				<Box className='inputnavbar'>
					<TextInput
						w={180}
						h={28}
						placeholder={t("ASK_ANYTHING")}
						leftSection={<IconWrapper name='AskAnythingIcon' />}
						radius='md'
						size='xs'
						variant='filled'
					/>
				</Box>

				<NavbarSection>
					{navItems.map((item, index) => (
						<SidebarItem
							key={index}
							icon={item.icon}
							label={item.label}
							path={item.path}
							isSelected={isPathSelected(location, item.path)}
							onClick={() => handleItemClick(item.path)}
							isNavItem={true}
						/>
					))}
				</NavbarSection>

				<NavbarSection className='tables-nav sign-out'>
					<SidebarItem
						icon='sign-out'
						label={t("SIGN_OUT")}
						path='#'
						isSelected={false}
						onClick={handleLogout}
						isNavItem={true}
					/>
				</NavbarSection>
			</NavbarScrollArea>
		</NavbarContainer>
	);
};

export default AdminNavbar;
