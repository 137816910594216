import { useEffect } from "react";
import axios from "axios";
import { services } from "../services";
import { jwtDecode } from "jwt-decode";

const isValidISODate = (dateString: string): boolean => !isNaN(Date.parse(dateString));

export const fetchSystemDate = async (): Promise<void> => {
  try {

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) throw new Error("Access token not found");

    const decodedToken: any = jwtDecode(accessToken);
    const fundGroupJwt = decodedToken?.permissions?.map((perm: any) => ({
      fundGroup: perm.fund_group,
      permissions: perm.fund_group_permissions,
      orderLimit: perm.orders_limit
    }));
    const fundGroup = fundGroupJwt[0].fundGroup;
    if (!fundGroupJwt || fundGroupJwt.length === 0) throw new Error("Fund group not found in token");

    localStorage.setItem("fundGroup", JSON.stringify(fundGroupJwt));
    const response = await axios.get(`${services.SYSTEM_DATE}?fund_group=${fundGroup}`);
    const { api_status, data } = response.data;

    if (api_status !== 'success' || !data?.length) throw new Error('Invalid API response structure');

    const systemDate = data[0].todays_date;
    if (isValidISODate(systemDate)) {
      localStorage.setItem('systemDate', systemDate);
    } else {
      throw new Error(`Invalid system date format: ${systemDate}`);
    }
  } catch (error) {
    const err = error as Error;
    if (process.env.NODE_ENV !== 'production') {
      console.error('Error fetching system date:', err.message);
    }
  }
};

export const useSystemDatePolling = (intervalMs = 300000, skipInitialFetch = false) => {
  useEffect(() => {
    if (!skipInitialFetch) {
      fetchSystemDate();
    }

    try {
      const interval = setInterval(() => fetchSystemDate(), intervalMs);
      return () => clearInterval(interval);
    } catch (error) {
      const err = error as Error;
      if (process.env.NODE_ENV !== 'production') {
        console.error('Error polling:', err.message);
      }
    }

  }, [intervalMs, skipInitialFetch]);
};
