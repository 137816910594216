import { KeycloakContext } from "../Keycloak/KeycloakProvider";
import { useContext } from "react";

export const useLogout = () => {
	const { logout } = useContext(KeycloakContext);

	const handleLogout = () => {
		localStorage.clear();
		sessionStorage.clear();
		logout();
	};

	return handleLogout;
};
