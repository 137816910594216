import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { getRealm } from "../constants";

const TOKEN_KEYS = {
	ACCESS_TOKEN: "accessToken",
	REFRESH_TOKEN: "refreshToken",
	EXPIRY_DATE: "expiryDate",
	IS_AUTHENTICATED: "isAuthenticated",
};

const getConfig = () => {
	const origin = localStorage.getItem("origin");
	if (!origin) {
		throw new Error("Origin not found. Make sure OriginProvider is properly initialized.");
	}

	const keycloakUrl = window.KEYCLOAK_URL;
	const clientId = process.env.REACT_APP_CLIENT_ID;
	const realm = getRealm(origin);

	return {
		keycloakTokenUrl: `${keycloakUrl}realms/${realm}/protocol/openid-connect/token`,
		clientId,
	};
};

const getLocal = (key: string): string | null => localStorage.getItem(key);
const setLocal = (key: string, value: string): void => localStorage.setItem(key, value);
const clearLocal = (key: string): void => localStorage.removeItem(key);

export const getToken = (): string | null => getLocal(TOKEN_KEYS.ACCESS_TOKEN);
export const getRefreshToken = (): string | null => getLocal(TOKEN_KEYS.REFRESH_TOKEN);
export const setAccessToken = (token: string): void => setLocal(TOKEN_KEYS.ACCESS_TOKEN, token);
export const setRefreshToken = (token: string): void => setLocal(TOKEN_KEYS.REFRESH_TOKEN, token);

export const isTokenExpired = (token: string, offsetSeconds = 0) => {
	try {
		const decoded: any = jwtDecode(token);
		if (decoded.exp === undefined) {
			return false;
		}

		const currentTime = Math.floor(Date.now() / 1000);
		return decoded.exp <= currentTime + offsetSeconds;
	} catch (e) {
		return false;
	}
};

export const isTokenExpiringSoon = (token: string | null): boolean => {
	if (!token) return true;

	const decodedToken: any = jwtDecode(token);
	const expiringThreshold = 2 * 60;
	const currentTime = Math.floor(Date.now() / 1000);
	return decodedToken.exp - currentTime < expiringThreshold;
};

export const refreshTokenIfNeeded = async (): Promise<string | null> => {
	const token = getToken();

	if (!token || isTokenExpired(token) || isTokenExpiringSoon(token)) {
		const refreshToken = getRefreshToken();
		if (!refreshToken) {
			throw new Error("Refresh token not available");
		}

		try {
			const response = await axios.post(
				getConfig().keycloakTokenUrl,
				new URLSearchParams({
					client_id: getConfig().clientId || "",
					grant_type: "refresh_token",
					refresh_token: refreshToken,
				}),
			);

			const { access_token, refresh_token } = response.data;
			setLocal(TOKEN_KEYS.ACCESS_TOKEN, access_token);
			setLocal(TOKEN_KEYS.REFRESH_TOKEN, refresh_token);
			console.log("Token refreshed successfully");

			return access_token;
		} catch (error) {
			handleApiError(error);
		}
	}

	return token;
};

export const clearTokens = (): void => {
	clearLocal(TOKEN_KEYS.ACCESS_TOKEN);
	clearLocal(TOKEN_KEYS.REFRESH_TOKEN);
	clearLocal(TOKEN_KEYS.EXPIRY_DATE);
	clearLocal(TOKEN_KEYS.IS_AUTHENTICATED);
};

export const validateTokens = async (): Promise<boolean> => {
	const token = getToken();
	const refreshToken = getRefreshToken();

	if (!token || !refreshToken) {
		clearTokens();
		return false;
	}

	if (isTokenExpired(token)) {
		try {
			const newToken = await refreshTokenIfNeeded();
			return !!newToken;
		} catch (error) {
			console.error("Error refreshing token:", error);
			clearTokens();
			return false;
		}
	}

	return true;
};

export const handleApiError = (error: any): void => {
	console.error("API Error:", error.response ? error.response.data : error.message);
	throw new Error("API Error occurred");
};
