import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MarketCounterSingle } from "../types";
import axios from "axios";
import { services } from "../services";
import { logoutReload } from "../constants/logoutReload";
import { fetchSystemDate } from "../utils/useSystemDate";
import dayjs from 'dayjs';

// Initial state setup
const initialMarketCounters = {
  ready_state_counts: {
    total_funds: 0,
    in_with_orders: 0,
    out_with_orders: 0,
    out_with_no_orders: 0,
  },
  not_ready_state_counts: {
    total_funds: 0,
    data_missing: 0,
    benchmark_not_updated: 0,
    start_of_day_recon_not_done: 0,
  },
  completed_state_counts: {
    total_funds: 0,
    in_with_no_order: 0,
    in_after_placing_order: 0,
    out_approved_with_overrides: 0,
  },
  total_funds_in_aggregate_count: 0,
};

interface MarketCounterFuture extends MarketCounterSingle {
  target_date: string;
}

interface MarketState {
  loading: boolean;
  error: string | null;
  counters: {
    market_on_open_counters: MarketCounterSingle;
    market_counters: MarketCounterSingle;
    market_on_close_counters: MarketCounterSingle;
    market_on_close_plus_days_counters: MarketCounterSingle;
    moc_future_days_counters: MarketCounterFuture[];
  };
  as_of_date: string | null;
  target_date: string | null;
  workItemsCount: number;
}

const initialState: MarketState = {
  loading: false,
  error: null,
  counters: {
    market_on_open_counters: initialMarketCounters,
    market_counters: initialMarketCounters,
    market_on_close_counters: initialMarketCounters,
    market_on_close_plus_days_counters: initialMarketCounters,
    moc_future_days_counters: [],  // Initialize as empty array for future days
  },
  as_of_date: null,
  target_date: "",  // Optional or dependent on further use cases
  workItemsCount: 0,
};

export const fetchFundManagerCounters = createAsyncThunk(
  "market/fetchFundManagerCounters",
  async (username: string, { dispatch }) => {
    try {
      await fetchSystemDate();

      const systemDate = localStorage.getItem("systemDate");
      if (!systemDate || !dayjs(systemDate, "YYYY-MM-DD", true).isValid()) {
        throw new Error("Invalid or missing system date");
      }

      const queryParams = {
        calc_date: systemDate,
        username,
      };

      const countersPromise = axios.get(
        `${services.FUND_MANAGER_COUNTERS}?${new URLSearchParams(queryParams)}`
      );
      const workItemsPromise = axios.get(services.WORK_ITEM);

      const [countersResponse, workItemsResponse] = await Promise.all([
        countersPromise,
        workItemsPromise,
      ]);

      // Handle counters response
      if (countersResponse.data?.data) {
        const data = countersResponse.data.data;
        dispatch(updateState({ key: "counters", value: data }));
        dispatch(updateState({ key: "as_of_date", value: data.calc_date }));
      } else {
        console.warn("No counters data found. Setting counters to initial values.");
        dispatch(updateState({ key: "error", value: null }));
        dispatch(updateState({ key: "counters", value: initialMarketCounters }));
      }
      

      // Handle work items response
      if (workItemsResponse.data?.data) {
        const workItems = workItemsResponse.data.data;
        const todayItems = workItems?.today || [];
        dispatch(updateState({ key: "workItemsCount", value: todayItems.length }));
      } else {
        throw new Error("No work items data found");
      }
      dispatch(updateState({ key: "error", value: null }));
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        const status = e.response.status;
        const detail = e.response.data?.detail;
        if (status === 403 && detail === "Token has expired") {
          logoutReload();
        }
      }
      dispatch(updateState({ key: "error", value: "Error fetching data" }));
    } finally {
      dispatch(updateState({ key: "loading", value: false }));
    }
  }
);



// Reducers and actions
export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    updateState: (state, action) => {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value,
      };
    },
    resetState: () => initialState,
  },
});

export const { updateState } = marketSlice.actions;

export default marketSlice.reducer;

