import React, { useCallback, useEffect, useState } from "react";
import { Button, Flex, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { services } from "../../../../services";
import { FundPayload } from "../../../../types";
import { InputError } from "../../../../components/Common/Errors/InputError";
import { validateCreateFundData } from "../../../../utils/validations";
import { toast, ToastContainer } from 'react-toastify';
import { CustomSelectBox } from "../../../../components/Common/Select/CustomSelectBox";
import IconWrapper from "../../../../components/Common/IconWrapper";
import { dynamicKeys, halfWidths, initialFormData, inputList } from "./constant";
import { AssetTextInput } from "../../../../components/AssetsInvestments/AssetTextInput";

type createEditFundProps = {
    isEditFund: boolean;
    editableData?: FundPayload;
};
interface createFundErrors {
    [key: string]: string
}

export const CreateEditFund: React.FC<createEditFundProps> = ({ isEditFund, editableData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [inputErrors, setInputErrors] = useState<createFundErrors>({})
    const [createFormData, setCreateFormData] = useState<FundPayload>(
        isEditFund ? (editableData as FundPayload) : initialFormData,
    );
    const [existingFundId, setExistingFundId] = useState<string[]>([]);

    const handleInputChange = (key: string, value: any) => {
        switch (key) {
            case "fund_managers":
                setCreateFormData((prev) => ({ ...prev, [key]: [value] }));
                break;
            default:
                setCreateFormData((prev) => ({ ...prev, [key]: value }));
                break;
        }
    };
    const handleInputFocus = (key: string) => {
        setInputErrors((prevErrors) => {
            const { [key]: _, ...rest } = prevErrors;
            return rest
        });
    }
    const checkDuplicateFundId = (fundId: string) => {
        const isFundIdExist = existingFundId.includes(fundId);
        if (isFundIdExist) setInputErrors((errors) => ({ ...errors, fund_id: "Fund ID already exist" }))
    }
    const submitCreateFund = async (createFormData: FundPayload) => {
        const checkErrors = validateCreateFundData(createFormData);
        if (Object.keys(checkErrors).length > 0 || Object.keys(inputErrors).length > 0) {
            setInputErrors({ ...inputErrors, ...checkErrors })
        } else {
            setInputErrors({})
            try {
                await axios.post(services.FUND_ATTRIBUTES, [createFormData]);
                setCreateFormData(initialFormData);
                navigate(-1);
            } catch (error: any) {
                console.log('error creating fund : ', error)
                toast.error(error.response.data.detail)
            }
        }
    };

    const cancelCreateFund = () => {
        setInputErrors({})
        setCreateFormData(initialFormData);
        navigate(-1);
    };

    const fetchData = useCallback(async () => {
        try {
            const response = await axios.get(services.FUND_ATTRIBUTES);
            const filterFundIds = (response.data.data).map((el: FundPayload) => el.fund_id)
            setExistingFundId(filterFundIds)
        } catch (error) {
            console.log(error)
        }
    }, []);

    useEffect(() => {
        fetchData()
        return () => setExistingFundId([])
    }, [])
    return (
        <>
            <Flex m={"35px auto 0px"} w={"495px"} direction={"column"} className='create-fund'>
                {inputList.map((ele, index) => {
                    if (dynamicKeys.includes(ele.apiKey)) {
                        return (
                            <div  key={ele.apiKey} className={`input-div ${halfWidths.includes(ele.apiKey) ? 'half-width' : ""} `}>
                                <AssetTextInput
                                    label={ele.label}
                                    value={createFormData[ele.apiKey as keyof FundPayload] as string || ""}
                                    onChangeAutoComplete={(value: string) => handleInputChange(ele.apiKey, value)}
                                    className={"auto-suggest-text-input"}
                                    autoComplete
                                    onFocus={() => handleInputFocus(ele.apiKey)}
                                    errorMessage={inputErrors[ele.apiKey]}
                                />
                            </div>
                        )
                    }
                    else if (ele.apiKey === "forty_act_fund") {
                        return (
                            <div className="select-input half-width" key={ele.apiKey}>
                                <CustomSelectBox
                                    label={t(ele.label)}
                                    data={[
                                        { value: 'Y', label: t("Y") },
                                        { value: 'N', label: t("N") },
                                    ]}
                                    value={createFormData[ele.apiKey] ? "Y" : "N"}
                                    rightSection={<IconWrapper name="select-dropdown-large" />}
                                    onChange={(value) => {
                                        if (value === "Y") { handleInputChange(ele.apiKey, true) }
                                        else if (value === "N") { handleInputChange(ele.apiKey, false) }
                                    }}
                                />
                            </div>
                        );
                    } 
                    else {
                        return (
                            <div className="input-div" key={ele.apiKey}>
                                <TextInput
                                    label={t(ele.label)}
                                    value={createFormData[ele.apiKey as keyof FundPayload] as string || ""}
                                    w={index < 2 ? "100%" : index > 8 ? "100%" : "50%"}
                                    onChange={(e) => handleInputChange(ele.apiKey, e.currentTarget.value)}
                                    onFocus={() => handleInputFocus(ele.apiKey)}
                                    onBlur={ele.apiKey === "fund_id" && !isEditFund ? (e) => checkDuplicateFundId(e.currentTarget.value) : undefined}
                                    className={`${isEditFund && ele.apiKey === "fund_id" ? "disabled-input" : ""}`}
                                    readOnly={isEditFund && ele.apiKey === "fund_id"}
                                />
                                {inputErrors[ele.apiKey] && <InputError message={inputErrors[ele.apiKey]} />}
                            </div>
                        );
                    }
                })}

                <Flex m={"34px 0 280px 226px"} w={"270px"}>
                    <Button
                        className='cancel-btn'
                        onClick={() => {
                            cancelCreateFund();
                        }}
                    >
                        
                    </Button>
                    <Button className='create-btn' onClick={() => submitCreateFund(createFormData)}>
                        {isEditFund ? "Update" : "Create"}
                    </Button>
                </Flex>
            </Flex>
            <ToastContainer />

        </>
    );
};
