
export const clearAllStorage = () => {
	// Clear all localStorage
	localStorage.clear();

	// Clear all sessionStorage
	sessionStorage.clear();

	// Clear any specific keys you want to ensure are removed
	localStorage.removeItem("persist:root");
	localStorage.removeItem("chatList");
	localStorage.removeItem("currentChat");

	// You can add more specific items to clear if needed
};
