import React, { useState, useEffect, useRef, useReducer } from "react";
import { ActionIcon, Collapse, Flex, useMantineTheme, Grid } from "@mantine/core";
import { Data, Document, DocumentDrop, PresetOption2Props, QueryFormDataProp } from "../../../types";
import { services } from "../../../services";
import axios from "axios";
import ContextMenu from "../ContextMenu2";
import { v4 as uuid_v4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Preset.css";
import useClickOutside from "../Presets/useClickOutside";
import {
	ContextMenuState,
	handleEvent,
	handleSave as saveRenameFolder,
	updateContextMenu,
} from "../../../utils/handleRename";
import { t } from "i18next";
import { initialQueryFormData, QueryForm } from "./QueryForm";
import { formatDate, savePreset } from "./PresetsUtils";
import "./PresetsOption2.css";
import { IconChevronLeft, IconChevronRight, IconX } from "@tabler/icons-react";
import FolderList from "./FolderList";
import FolderCreationInput from "./FolderCreationInput";
import SearchSection from "./SearchInput";
import StoredFoldersList from "./StoredFoldersList";
import FolderManagement from "./FolderManagement";
import { useRename } from "../../../utils/hooks/useRename";
import { useFetchData } from "../../../utils/hooks/useFetchData";
import { useFolderCreation } from "../../../utils/hooks/useFolderCreation";
import { useFolderOptions } from "../../../utils/hooks/useFolderOptions";
import { DeleteModal } from "../../Common/Modals/DeleteModal";
import { deleteModalReducer, initialDeleteState } from "./hooks/deleteModalReducer";
import { WarningModal } from "../../Common/Modals/WarningModal";
import { getDatesFromTodayReference, getDaysDifferenceFromToday } from "../../../utils/calendarUtils";

interface Folder {
	id: string;
	name: string;
	documents: Document[];
}

export function Preset({
	onSubmit,
	addRecentPresets,
	selectedPreset,
	handleRefresh,
	setErrorMessage,
	isPresetOpen,
	setIsPresetOpen,
	isLeftNavOpen,
	setIsLeftNavOpen,
}: PresetOption2Props) {
	const theme = useMantineTheme();
	const DEFAULT_FOLDER_NAME = "untilted folder";
	const { data, setData, storedFolders, fetchData } = useFetchData();
	const [newEmptyFolderNameDelete, setEmptyNewFolderNameDelete] = useState<boolean>(false);
	const [newEmptyFolderName, setNewEmptyFolderName] = useState("");
	const [isSavePresetModalOpen, setIsSavePresetModalOpen] = useState(false);
	const [presetName, setPresetName] = useState<string>("");
	const [isLoading, setIsLoading] = useState(false);
	const [selectedFolderName, setSelectedFolderName] = useState("");
	const [totalGrid, leftGrid, centerGrid, rightGrid] = [12, 1.5, 9, 1.5];
	const inputRef = useRef<HTMLInputElement>(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [filteredFolders, setFilteredFolders] = useState(data);
	const [expandedFolder, setExpandedFolder] = useState<string[]>([]);
	const [highlightedPreset, setHighlightedPreset] = useState<string | null>(null);
	const { folderOptions, fetchFolderOptions, checkDuplicatePreset } = useFolderOptions();
	const [deleteModalDetails, dispatch] = useReducer(deleteModalReducer, initialDeleteState);
	const [queryFormData, setQueryFormData] = useState<QueryFormDataProp>(initialQueryFormData)
	const [warningModalsDetails, setWarningModalsDetails] = useState({
		opened: false,
		title: "",
		message: "",
	})

	const [draggedDocument, setDraggedDocument] = useState<{
		doc: Document;
		sourceFolderId: string;
	} | null>(null);

	const {
		creatingFolder,
		setCreatingFolder,
		folderTitle,
		setFolderTitle,
		handleInputKeyPressCreateButton,
		handleInputBlur,
	} = useFolderCreation({ fetchData, fetchFolderOptions, DEFAULT_FOLDER_NAME });

	useEffect(() => {
		handleDocumentClick(selectedPreset);
		if (!isLeftNavOpen) setIsLeftNavOpen(true);
	}, [selectedPreset]);

	useEffect(() => {
		if (data.length > 0) localStorage.setItem("presetName", JSON.stringify(data));
	}, [data]);

	useEffect(() => {
		!creatingFolder && setFolderTitle("");
	}, [creatingFolder]);

	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem("presetName") || "[]");

		const hasFavorites = storedData.some((folder: { _id: string }) => folder._id === "Favorites");
		if (!hasFavorites) {
			storedData.unshift({
				_id: "Favorites",
				documents: [],
				message: "Default Favorites folder",
			});
		}

		setData(storedData);
	}, []);

	useEffect(() => {
		if (!searchQuery.trim()) {
			setFilteredFolders(data);
		} else {
			const lowerQuery = searchQuery.toLowerCase();

			const filtered = data
				.map((folder) => {
					const folderMatches = folder._id?.toLowerCase().includes(lowerQuery);

					const filteredDocuments = folder.documents.filter(
						(doc) =>
							doc.name?.toLowerCase().includes(lowerQuery) ||
							doc.folder_name?.toLowerCase().includes(lowerQuery),
					);

					if (folderMatches || filteredDocuments.length > 0) {
						return {
							...folder,
							documents: filteredDocuments,
						};
					}

					return null; 
				})
				.filter((folder): folder is Data => folder !== null); 

			setFilteredFolders(filtered);
		}
	}, [searchQuery, data]);

	const handleDocumentClick = (uuid: string) => {
		setIsLoading(true);
		const filteredData = data.filter((folder) => folder._id !== "Untitled Folder");

		try {
			if (data) {
				let presetItem:any = null;

				for (const folder of filteredData) {
					presetItem = folder.documents.find((item: { uuid: string }) => item.uuid === uuid);
					if (presetItem) break;
				}

				if (presetItem) {
					addRecentPresets(presetItem.uuid);
					setQueryFormData((prevFormData) => { return { ...prevFormData, fundId: presetItem.funds?.[0] } });
					setQueryFormData((prevFormData) => { return { ...prevFormData, assetId: presetItem.asset_specific_fields?.asset_keys?.[0] } });
					setQueryFormData((prevFormData) => { return { ...prevFormData, assetIdType: presetItem.asset_specific_fields?.asset_key_types } });
					setQueryFormData((prevFormData) => { return { ...prevFormData, fundGroup: presetItem.fund_group?.[0] } });
					setQueryFormData((prevFormData) => { return { ...prevFormData, selectedTypeOfData: presetItem.data_type } });
					setQueryFormData((prevFormData) => { return { ...prevFormData, selectedFromDate: getDatesFromTodayReference(presetItem.start_date_offset) } });
					setQueryFormData((prevFormData) => { return { ...prevFormData, selectedToDate: getDatesFromTodayReference(presetItem.end_date_offset) } });
					setIsPresetOpen(false);
				} else {
					console.warn("Preset data not found for the provided UUID.");
				}
			} else {
				console.warn("No data found in local storage.");
			}
		} catch (error) {
			console.error("Error fetching document data:", error);
		} finally {
			setIsLoading(false);
		}
	};
	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);

		const matchingFolders: string[] = [];
		const results = data
			.map((folder) => {
				const matchingDocuments = folder.documents.filter(
					(doc) =>
						doc.name?.toLowerCase().includes(query) || folder._id?.toLowerCase().includes(query),
				);

				if (matchingDocuments.length > 0) {
					matchingFolders.push(folder._id); 
					return {
						...folder,
						documents: matchingDocuments,
					};
				}

				return null;
			})
			.filter(Boolean); 

		setFilteredFolders(results as Data[]);
		setExpandedFolder(matchingFolders); 
	};
	const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			setSearchQuery(""); 
		}
	};
	const handleSubmit = async () => {
		handleRefresh();
		setIsLoading(true);
		await onSubmit(queryFormData);
		setIsLoading(false);
	};

	const handleSave = async (folderId: string | null = null) => {
		if (!presetName.trim()) {
			console.warn("Preset name cannot be empty.");
			return;
		}

		const isDuplicateNow = checkDuplicatePreset(presetName.trim(), selectedFolderName.trim());
		if (isDuplicateNow) {
			console.warn("Preset name already exists in the selected folder.");
			return;
		}

		const uuid = uuid_v4();
		try {
			await savePreset(folderId, uuid, {
				presetName: presetName.trim(),
				selectedFolderName: selectedFolderName.trim(),
				fundGroup:queryFormData.fundGroup,
				fundId:queryFormData.fundId,
				selectedFromDate: getDaysDifferenceFromToday(queryFormData.selectedFromDate as Date),
				selectedToDate: getDaysDifferenceFromToday(queryFormData.selectedToDate as Date),
				selectedTypeOfData:queryFormData.selectedTypeOfData,
				assetId : queryFormData.assetId,
				assetIdType: queryFormData.assetIdType
			});
			setQueryFormData(()=>initialQueryFormData)

			await fetchData();
			await fetchFolderOptions();

			setExpandedFolder(
				selectedFolderName === "Untitled Folder" ? ["Favorites"] : [selectedFolderName],
			);
			setHighlightedPreset(uuid);

			toggleSavePresetModal();
			togglePreset();
		} catch (error: any) {
			console.error("Error making API request:", error);
			setErrorMessage(error.message);
		}
	};

	const toggleSavePresetModal = () => setIsSavePresetModalOpen(!isSavePresetModalOpen);

	const [contextMenu, setContextMenu] = useState<ContextMenuState>({
		visible: false,
		x: 0,
		y: 0,
		renameFunction: () => {},
		deleteFunction: () => {},
	});

	const contextMenuRef = useRef<HTMLDivElement>(null);
	useClickOutside({
		ref: contextMenuRef,
		handler: () => setContextMenu({ ...contextMenu, visible: false }),
		condition: contextMenu.visible,
	});

	const togglePreset = () => setIsPresetOpen(!isPresetOpen);

	const {
		renamingDocId,
		renamingFolderId,
		newName,
		setNewName,
		newNameFolder,
		setNewNameFolder,
		handleRenamePreset,
		handleRenameFolder,
		handleRenamePresetEvent,
		handleRenameFolderEvent,
	} = useRename({
		data,
		fetchData,
	});

	const handlePresetRightClick = (
		event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
		docId: string,
	) =>
		updateContextMenu(event, setContextMenu, {
			renameFunction: () => handleRenamePreset(docId),
			deleteFunction: () => showDeletePresetModal(docId),
		});

	const handleFolderRightClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		folderId: string,
		folderName: string,
	) =>
		updateContextMenu(event, setContextMenu, {
			renameFunction: () => handleRenameFolder(folderId, folderName),
			deleteFunction: () => showDeleteFolderModal(folderId, folderName),
		});
	const showDeletePresetModal = (docId: string) => {
		setContextMenu({ ...contextMenu, visible: false });
		dispatch({
			type: "presetReference", payload: {
				isOpen: true,
				title: "DELETE_PRESET",
				message: "ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_PRESET?",
				presetId: docId
			}
		})
	}

	const showDeleteFolderModal = (folderId: string, folderName: string) => {
		setContextMenu({ ...contextMenu, visible: false });
		dispatch({
			type: "folderReference", payload: {
				isOpen: true,
				title: "DELETE_PRESET_FOLDER",
				message: "ARE_YOU_SURE_YOU_WOULD_LIKE_TO_DELETE_THIS_FOLDER?",
				folderId,
				folderName
			}
		})
	};

	const acceptModalWarning = () => {
		setWarningModalsDetails((prev) => {
			return {
				...prev,
				title: "",
				message:"",
				opened: false,
		}})
	}
	const handleDeleteApproval = async () => {
		const { folderId, folderName, presetId } = deleteModalDetails
		dispatch({ type: "resetDeleteModal" })
		presetId ? await handlePresetDelete(presetId) : await handleDeleteFolder(folderId as string, folderName as string)
	};

	const handlePresetDelete = async (docId: string) => {
		setContextMenu({ ...contextMenu, visible: false });
		if (docId) {
			try {
				const folderUUIDdelete = data.find((item) =>
					item.documents.some((doc) => doc.uuid === docId),
				)?._id;
				await axios.delete(`${services.GET_PRESETS}/${docId || folderUUIDdelete}`);
				fetchData();
			} catch (error) {
				console.error("Error deleting document:", error);
			}
		}
	};

	const handleDeleteFolder = async (folderId: string, folderName: string) => {
		setContextMenu({ ...contextMenu, visible: false });

		if (!folderId) {
			console.error("No folder ID provided for deletion.");
			return;
		}

		const matchingFolder = data.find((item) => item._id === folderName);
		const hasPresets = (matchingFolder?.documents || []).some(
			(doc) => doc.name && doc.name.trim() !== "",
		);

		if (hasPresets) {
			setWarningModalsDetails((prev) => {
				return {
					...prev,
					opened: true,
					title: "EMPTY_FOLDER_BEFORE_DELETE",
					message: "Please move or delete presets inside before deleting the preset folder."
				}
			})
			return;
		}

		try {
			const response = await axios.delete(
				`${services.GET_PRESETS}/${matchingFolder?.documents[0].uuid}`,
			);

			if (response.status === 200 && response.data.data === "SUCCESS") {
				const updatedData = data.filter((item) => item._id !== folderName);
				setData(updatedData);

				localStorage.setItem("presetName", JSON.stringify(updatedData));
				toast.success(t("FOLDER_DELETED_SUCCESSFULLY"));
			} else {
				console.error("Backend deletion failed:", response.data.message);
				toast.error(response.data.message || t("ERROR_DELETE_FAILED"));
			}
		} catch (error) {
			console.error("Error during folder deletion:", error);
			toast.error(t("ERROR_DELETE_FAILED"));
		}
	};

	const handleKeyDownEmpty = (event: { key: string }) =>
		handleEvent(event, newEmptyFolderName, "folderName", setEmptyNewFolderNameDelete);
	const handleBlurSaveEmpty = () =>
		saveRenameFolder(newEmptyFolderName, "folderName", setEmptyNewFolderNameDelete);
	const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		event.target.select();
	};
	const mapDataToFolders = (data: Data[]): Folder[] => {
		return data
			.filter((item) => item._id !== null)
			.map((item) => ({
				id: item._id === "Untitled Folder" ? "Favorites" : item._id,
				name: item._id === "Untitled Folder" ? "Favorites" : item._id,
				documents: (item.documents || []).filter(
					(doc) => doc.name && doc.fund_group && doc.fund_group.length > 0,
				),
			}));
	};

	const handleDocumentDragStart = (doc: Document, sourceFolderId: string) => {
		setDraggedDocument({ doc, sourceFolderId });
	};

	const handleFolderDrop = async (targetFolderId: string) => {
		if (!draggedDocument) return;

		const { doc, sourceFolderId } = draggedDocument;

		setData((prevData: Data[]) =>
			prevData.map((folder) => {
				if (folder._id === sourceFolderId) {
					return {
						...folder,
						documents: folder.documents.filter((document) => document.uuid !== doc.uuid),
					};
				}
				if (folder._id === targetFolderId) {
					return { ...folder, documents: [...folder.documents, doc] };
				}
				return folder;
			}),
		);

		try {
			await axios.post(`${services.GET_PRESETS}`, [
				{
					uuid: doc.uuid,
					folder_name: targetFolderId,
				},
			]);

			toast.success(`Moved "${doc.name}" to folder "${targetFolderId}" successfully.`);
		} catch (error) {
			console.error("Error moving document:", error);

			toast.error("Failed to move the document. Reverting changes...");
			fetchData();
		} finally {
			setDraggedDocument(null);
		}
	};
	return (
		<Flex className='preset-option2-container'>
			<Collapse
				in={isLeftNavOpen}
				className='preset-leftNav'
				transitionTimingFunction='ease'
				transitionDuration={100}
			>
				<FolderManagement
					isPresetOpen={isPresetOpen}
					isLeftNavOpen={isLeftNavOpen}
					togglePreset={togglePreset}
					setCreatingFolder={setCreatingFolder}
					theme={theme}
				/>

				<Collapse
					key='presets'
					in={isPresetOpen}
					transitionTimingFunction={isPresetOpen ? "ease-in" : "ease-out"}
					className='presets-list'
				>
					<SearchSection
						totalGrid={totalGrid}
						centerGrid={centerGrid}
						rightGrid={rightGrid}
						searchQuery={searchQuery}
						handleSearch={handleSearch}
						onKeyDown={handleSearchKeyDown} 
						placeholder={`${t("SEARCH")} ${t("PRESETS")}`}
					/>
					{creatingFolder && (
						<Grid columns={totalGrid} className='folder-section'>
							<Grid.Col span={leftGrid}></Grid.Col>
							<Grid.Col span={centerGrid} className='folder-wrapper'>
								<FolderCreationInput
									folderTitle={folderTitle}
									onChange={(e) => setFolderTitle(e.target.value)}
									onKeyPress={handleInputKeyPressCreateButton}
									onBlur={handleInputBlur}
									placeholder={DEFAULT_FOLDER_NAME}
									theme={theme}
								/>
							</Grid.Col>
						</Grid>
					)}
					<Grid columns={totalGrid} className='folder-list'>
						<Grid.Col span={leftGrid}></Grid.Col>
						<Grid.Col span={centerGrid + rightGrid}>
							<StoredFoldersList
								storedFolders={storedFolders}
								theme={theme}
								inputRef={inputRef}
								newEmptyFolderNameDelete={newEmptyFolderNameDelete}
								newEmptyFolderName={newEmptyFolderName}
								setNewEmptyFolderName={setNewEmptyFolderName}
								handleKeyDownEmpty={handleKeyDownEmpty}
								handleBlurSaveEmpty={handleBlurSaveEmpty}
								handleFocus={handleFocus}
							/>

							{!creatingFolder && (
								<FolderList
									folders={mapDataToFolders(filteredFolders)} 
									expandedFolders={expandedFolder}
									highlightedPreset={highlightedPreset}
									setHighlightedPreset={setHighlightedPreset}
									renamingDocId={renamingDocId}
									renamingFolderId={renamingFolderId}
									newNameFolder={newNameFolder}
									setNewNameFolder={setNewNameFolder}
									newName={newName}
									setNewName={setNewName}
									onRenameDoc={handleRenamePresetEvent}
									onRenameFolder={handleRenameFolderEvent}
									handleRenameFolderEvent={handleRenameFolderEvent}
									handleRenamePresetEvent={handleRenamePresetEvent}
									onDocumentClick={handleDocumentClick}
									onFolderRightClick={handleFolderRightClick}
									onDocumentRightClick={handlePresetRightClick}
									onDocumentDragStart={handleDocumentDragStart}
									onFolderDrop={handleFolderDrop}
								/>
							)}
							<div ref={contextMenuRef}>
								<ContextMenu
									x={contextMenu.x}
									y={contextMenu.y}
									visible={contextMenu.visible}
									actionRename={contextMenu.renameFunction}
									actionDelete={contextMenu.deleteFunction}
									actionTextRename={t("RENAME")}
									actionTextDelete={t("DELETE")}
								/>
							</div>
						</Grid.Col>
					</Grid>
				</Collapse>
				<Collapse
					key='form'
					in={!isPresetOpen}
					transitionTimingFunction={isPresetOpen ? "ease-out" : "ease-in"}
				>
					<Grid columns={totalGrid} mt={"0.4rem"} pl={"xxs"}>
						<Grid.Col span={leftGrid / 3}></Grid.Col>
						<Grid.Col span={totalGrid - leftGrid / 3} className='query-form'>
							<QueryForm
								{...{
									queryFormData,
									setQueryFormData,
									toggleSavePresetModal,
									handleSubmit,
									presetName,
									setPresetName,
									isSavePresetModalOpen,
									handleSave,
									selectedFolderName,
									setSelectedFolderName,
								}}
							/>
						</Grid.Col>
					</Grid>
				</Collapse>
			</Collapse>
			<div className={`preset-leftNav-hidden ${isLeftNavOpen ? "" : "collapsed"}`}>
				<ActionIcon
					variant='transparent'
					c={theme.colors.grey[8]}
					onClick={
						isPresetOpen
							? togglePreset
							: () => {
									setIsLeftNavOpen(!isLeftNavOpen);
							  }
					}
				>
					{isPresetOpen ? (
						<IconX size={"1.2rem"} />
					) : isLeftNavOpen ? (
						<IconChevronLeft />
					) : (
						<IconChevronRight />
					)}
				</ActionIcon>
			</div>

			<ToastContainer position='top-right' autoClose={5000} />
			<DeleteModal
				opened={deleteModalDetails.isOpen}
				onClose={() => {
					dispatch({ type: "resetDeleteModal"})
				}}
				TITLE={deleteModalDetails.title as string}
				message={deleteModalDetails.deleteMessage as string}
				onSubmit={() => handleDeleteApproval()}
			/>
			<WarningModal
				opened={warningModalsDetails.opened}
				title={warningModalsDetails.title}
				message={warningModalsDetails.message}
				onSubmit={() => acceptModalWarning()}
			/>
		</Flex>
	);
}
