import React from "react";
import { Box, Text } from "@mantine/core";
import IconWrapper from "../../components/Common/IconWrapper";
import { t } from "i18next";
import ReactMarkdown from "react-markdown";
import { ChatQuestionAnswer } from "../../store/features/chat/types";

export const ChatBubble: React.FC<{ message: ChatQuestionAnswer  }> = ({
	message,
}) => {
	

	const renderContent = (data: string) => {
		if (data) {
			return (
				<Box mb='lg'>
					<ReactMarkdown>{data}</ReactMarkdown>
				</Box>
			);
		}
		return <Text>{t("NO_DATA_ERROR")}</Text>;
	};	

	return (<>
		<Box className={"chat-bubble question"} ta='left'>
			<Box className='message'>
				<Text>{message.question}</Text>
			</Box>
		</Box>
		<Box className={`chat-bubble answer'} `} ta='left'>
			{message.answer && <IconWrapper name='Logo-icon' className='ai-logo' />}
			<Box className='message'>
				{message.answer && renderContent(message.answer) }
			</Box>
		</Box>
	</>
	);
};
