import React, { ReactNode } from "react";
import { AppShell } from "@mantine/core";
import { useAppSelector } from "../../store/hooks";
import AdminNavbar from "../Leftnavbar/AdminNavbar";
import LeftNavbar from "../Leftnavbar/LeftNavbar";
import { isAdmin } from "../../utils/validations";

interface AuthLayoutProps {
	children: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
	const { user } = useAppSelector((state) => state.user);

	return (
		<AppShell padding='md' className='parent-container'>
			<div style={{ display: "flex", width: "100%" }}>
				<nav
					style={{
						flexShrink: 0,
						overflow: "hidden",
					}}
				>
					{isAdmin(user?.roles) ? <AdminNavbar /> : <LeftNavbar />}
				</nav>
				<div
					style={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						height: "100%",
						overflow: "auto",
					}}
				>
					{children}
				</div>
			</div>
		</AppShell>
	);
};

export default AuthLayout;
