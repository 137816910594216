import React from "react";
import "./ComingSoon.css"; // Create this CSS file for styling
import IconWrapper from "../../../components/Common/IconWrapper";
import { t } from "i18next";
import { Box, Text } from "@mantine/core";

const ComingSoon: React.FC = () => {
	return (
		<Box className='coming-soon'>
			<Box className='coming-soon__content'>
				<Box className='coming-soon__icon'>
					<IconWrapper name='star-mark' />
				</Box>
				<Text fw={600} size='1.3rem' className='coming-soon__title '>
					{t("COMING_SOON")}
				</Text>
				<Text className='coming-soon__text'>{t("COMING_SOON_MESSAGE")}</Text>
			</Box>
		</Box>
	);
};

export default ComingSoon;
