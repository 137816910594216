import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import "./AIAssistantPage.css";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ChatLayout } from "../../components/AIAssistant/ChatLayout";
import { newCategorizeChats } from "../../utils/chatUtils";
import { ChatProvider } from "../../contexts/ChatContext";
import {
	initialiseChat,
	setCurrentThreadChat,
	fetchAndUpdateChatList,
} from "../../store/features/chat/newChatSlice";

export const AIAssistant: React.FC = () => {
	const { symbol } = useParams();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { chatList, input, isLoading, currentThreadChat } = useAppSelector(
		(state) => state.newChat,
	);

	const [isLeftNavOpen, setIsLeftNavOpen] = useState(true);
	const [selectedItem, setSelectedItem] = useState<string>("new chat");

	// Effect to handle chat list persistence
	useEffect(() => {
		const loadChatList = async () => {
			try {
				await dispatch(fetchAndUpdateChatList());
			} catch (error) {
				console.error("Error fetching chat list:", error);
			}
		};
		loadChatList();
	}, []); 

	useEffect(() => {
		if (symbol && user) {
			dispatch(setCurrentThreadChat([]));

			const initialise = async () => {
				try {
					await dispatch(initialiseChat({ userName: user?.username, symbol }));
				} catch (error) {
					console.error("Error initializing chat:", error);
				}
			};
			initialise();
		}
	}, [symbol, user, dispatch]);

	const categorizedChats = useMemo(() => newCategorizeChats(chatList), [chatList]);

	return (
		<ChatProvider
			selectedItem={selectedItem}
			setSelectedItem={setSelectedItem}
			isLeftNavOpen={isLeftNavOpen}
			setIsLeftNavOpen={setIsLeftNavOpen}
			input={input}
			symbol={symbol}
		>
			<ChatLayout
				currentThreadChat={currentThreadChat}
				chatList={chatList}
				input={input}
				categorizedChats={categorizedChats}
				isLoading={isLoading}
			/>
		</ChatProvider>
	);
};
