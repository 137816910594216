import React from "react";
import ReactDOM from "react-dom/client";
import { MantineProvider } from "@mantine/core";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import { getConfig } from "./utils/conversions";
import App from "./App";
import { KeycloakContextProvider } from "./Keycloak/KeycloakProvider";
import { OriginProvider } from "./Keycloak/OriginContext";
import theme from "./constants/theme";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<MantineProvider defaultColorScheme='light' theme={theme}>
				<Router>
					<OriginProvider>
						<KeycloakContextProvider>
							<App />
						</KeycloakContextProvider>
					</OriginProvider>
				</Router>
			</MantineProvider>
		</PersistGate>
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const systemConfig = getConfig("system");
i18n.changeLanguage(systemConfig?.LANGUAGE?.value);
