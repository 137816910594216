import React from "react";
import { Grid } from "@mantine/core";
import { ChatSection } from "./ChatSection";
import { useAppSelector } from "../../store/hooks";
import { newCategorizeChats } from "../../utils/chatUtils";
import { NewCategorizedChats } from "../../store/features/chat/types";

interface AIChatHistoryProps {
	categorizedChats: NewCategorizedChats;

	selectedItem: string;
	onChatSelect: (label: string, id: string) => void;
	searchQuery: string;
}

export const AIChatHistory: React.FC<AIChatHistoryProps> = ({
	selectedItem,
	onChatSelect,
	searchQuery,
}) => {
	const { chatList } = useAppSelector((state) => state.newChat);

	const categorizedChats = newCategorizeChats(chatList);

	return (
		<Grid.Col span={10} offset={1}>
			{[
				{ title: "Today", chats: categorizedChats.today },
				{ title: "Yesterday", chats: categorizedChats.yesterday },
				{ title: "Last 7 Days", chats: categorizedChats.last7Days },
			].map(({ title, chats }) => (
				<ChatSection
					key={title}
					title={title}
					chats={chats}
					selectedItem={selectedItem}
					onChatSelect={onChatSelect}
				/>
			))}
		</Grid.Col>
	);
};
