import { FundPayload } from "../../../../types";

export const inputList = [
	{ label: "FUND_ID", apiKey: "fund_id" },
	{ label: "FUND_NAME", apiKey: "fund_name" },
	{ label: "FUND_CURRENCY", apiKey: "fund_currency" },
	{ label: "FUND_DOMICILE", apiKey: "fund_domicile" },
	{ label: "LEGAL_JURISDICTION", apiKey: "legal_jurisdiction" },
	{ label: "FORTY_ACT_FUND", apiKey: "forty_act_fund" },
	{ label: "BENCHMARK_ID", apiKey: "benchmark_id" },
	{ label: "FUND_GROUP", apiKey: "fund_group" },
	{ label: "OPTIMAL_FUTURES_BASKET_ID", apiKey: "optimal_futures_basket_id" },
	{ label: "PRIMARY_FUND_MANAGER", apiKey: "primary_fund_manager" },
	{ label: "SECONDARY_FUND_MANAGER", apiKey: "fund_managers" },
];

export const dynamicKeys = [
    'fund_name',
    'fund_currency',
    'legal_jurisdiction',
    'fund_domicile',
    'primary_fund_manager',
    'fund_managers',
  ];

  export const halfWidths = [
    'fund_currency',
    'fund_domicile',
    'legal_jurisdiction',
    'benchmark_id',
    'fund_group',
    'optimal_futures_basket_id'
  ]

 export const initialFormData: FundPayload = {
        fund_id: "", // req
        fund_name: "", //req
        fund_currency: "", // req
        fund_domicile: "",
        legal_jurisdiction: "",
        forty_act_fund: false,
        primary_fund_manager: "", //req
        fund_managers: [],
        benchmark_id: "", //req
        fund_structure: "MASTER",
        valuation_type: "MARKET_ON_OPEN", // req
        fund_group:"",
        optimal_futures_basket_id: "",
        qualified_institutional_investor: false,
        qualified_sophisticated_investor: false,
        eligible_under_rule_144a: false,
        parent_fund_id: "",
        market_on_open_activity_allowed: false,
    };