import React, {
	createContext,
	useState,
	useEffect,
	ReactNode,
	useCallback,
	useRef,
	useContext,
	useMemo,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	getToken,
	setAccessToken,
	clearTokens,
	refreshTokenIfNeeded,
	setRefreshToken,
	validateTokens,
	isTokenExpired,
} from "./tokenService";

import Keycloak from "keycloak-js";
import { Loader } from "@mantine/core";
import { OriginContext } from "./OriginContext";
import { getRealm } from "../constants";
import createKeycloakInstance from "./keycloakInstance";
import axios from "axios";
import { isAdmin, isFundManager } from "../utils/validations";
import { routes } from "../routes";
import { jwtDecode } from "jwt-decode";

interface KeycloakContextState {
	authenticated: boolean;
	keycloakValue: any;
	loading: boolean;
	error: Error | null;
	initialized: boolean;
}

interface KeycloakContextProps extends KeycloakContextState {
	logout: () => void;
}

const initialState: KeycloakContextState = {
	authenticated: false,
	keycloakValue: null,
	loading: true,
	error: null,
	initialized: false,
};

// const useStyles = createStyles((theme) => ({
// 	container: {
// 		display: "flex",
// 		justifyContent: "center",
// 		alignItems: "center",
// 		minHeight: "100vh",
// 		background:
// 			localStorage.getItem("colorScheme") === "light" ? "rgb(248, 249, 250)" : "rgb(36, 34, 57)",
// 	},
// 	loaderContainer: {
// 		textAlign: "center",
// 	},
// }));

const KeycloakContext = createContext<KeycloakContextProps>({
	...initialState,
	logout: () => { },
});

const KeycloakContextProvider = (props: { children: ReactNode }) => {
	// const { classes } = useStyles();
	const [state, setState] = useState<KeycloakContextState>(initialState);
	const stateRef = useRef(state);
	const navigate = useNavigate();
	const location = useLocation();
	const { origin } = useContext(OriginContext);
	const keycloakUrl = window.KEYCLOAK_URL;

	const keycloakRealm = getRealm(origin);

	const keycloakClientId = process.env.REACT_APP_CLIENT_ID;

	const keycloakInstance = useMemo(() => {
		if (!keycloakRealm) throw new Error("Keycloak realm is not defined");
		return createKeycloakInstance(keycloakRealm);
	}, [keycloakRealm]);
	const updateState = (newState: Partial<KeycloakContextState>) => {
		setState((prevState) => {
			const updatedState = { ...prevState, ...newState };
			stateRef.current = updatedState;
			return updatedState;
		});
	};

	const initKeycloak = useCallback(async () => {
		clearTokens();
		if (!keycloakInstance) {
			return new Error("Keycloak instance not found");
		}

		try {
			const isAuthenticated = await keycloakInstance.init({
				onLoad: "login-required",
				checkLoginIframe: false,
			});

			if (isAuthenticated) {
				const accessToken = keycloakInstance.token;
				if (accessToken) {
					setAccessToken(accessToken);
					localStorage.setItem("isAuthenticated", "true");
					localStorage.setItem("athena_A", accessToken);
					axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
				}

				const decodedToken: any = jwtDecode(accessToken);

				const hasNavigated = sessionStorage.getItem("hasNavigated");

				if (!hasNavigated) {
					if (isAdmin(decodedToken?.permissions[0].role) && location.pathname !== routes.USERS) {
						navigate(routes.USERS);
					} else if (isFundManager(decodedToken?.permissions[0].role) && location.pathname !== routes.HOME) {
						navigate(routes.HOME);
					}

					sessionStorage.setItem("hasNavigated", "true");
				}

				updateState({
					keycloakValue: keycloakInstance,
					authenticated: true,
					loading: false,
					initialized: true,
				});
			} else {
				keycloakInstance.login();
			}
		} catch (error) {
			console.error("Error initializing Keycloak: An authentication error occurred.");
			updateState({
				error: error as Error,
				loading: false,
				initialized: true,
			});
		}
	}, [keycloakInstance, navigate, location.pathname]);



	const logout = useCallback(async () => {
		try {
			const keycloakInstanceLogout = new Keycloak({
				url: keycloakUrl,
				realm: keycloakRealm,
				clientId: keycloakClientId,
			});
			await keycloakInstanceLogout.init({});
			await keycloakInstanceLogout.logout();

			await clearTokens();

			navigate("/");
		} catch (error) {
			console.error("Error during logout:", error);
		}
	}, []);

	useEffect(() => {
		const checkAuthentication = async () => {
			const token = getToken();
			const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

			if (token && isAuthenticated) {
				if (isTokenExpired(token)) {
					clearTokens();
					initKeycloak();
				} else {
					const isValid = await validateTokens();
					if (isValid) {
						if (keycloakInstance) {
							keycloakInstance.token = token;
						}
						updateState({
							authenticated: true,
							keycloakValue: keycloakInstance,
							loading: false,
							initialized: true,
						});
					} else {
						clearTokens();
						initKeycloak();
					}
				}
			} else {
				initKeycloak();
			}
		};

		checkAuthentication();
	}, [initKeycloak, origin]);

	useEffect(() => {
		if (stateRef.current.authenticated && stateRef.current.keycloakValue) {
			const refreshInterval = setInterval(async () => {
				try {
					const newToken = await refreshTokenIfNeeded();
					if (newToken) {
						axios.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
					}
					if (!newToken) {
						await logout();
					}
				} catch (error) {
					console.error("Failed to refresh token:", error);
					await logout();
				}
			}, 5 * 60 * 1000);

			return () => clearInterval(refreshInterval);
		}
	}, [state.authenticated, state.keycloakValue, logout, navigate]);

	return (
		<KeycloakContext.Provider value={{ ...state, logout }}>
			{state.loading ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						minHeight: "100vh",
					}}
				>
					<Loader size={65} color='blue' />
				</div>
			) : state.initialized ? (
				props.children
			) : null}
		</KeycloakContext.Provider>
	);
};

export { KeycloakContextProvider, KeycloakContext };
