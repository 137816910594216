import React, { ReactNode } from "react";
import { Container, Paper, useMantineTheme } from "@mantine/core";
import { RefObject } from "react";
import "../Leftnav.css";

interface NavbarContainerProps {
	children: ReactNode;
	paperRef?: RefObject<HTMLDivElement>;
}

const NavbarContainer: React.FC<NavbarContainerProps> = ({ children, paperRef }) => {
	const theme = useMantineTheme();

	return (
		<Container className='nav-container'>
			<Paper shadow='sm' bg={theme.colors.grey[0]} className='paper' ref={paperRef} h={"100vh"}>
				{children}
			</Paper>
		</Container>
	);
};

export default NavbarContainer;
