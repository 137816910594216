import Keycloak from "keycloak-js";

const keycloakUrl = window.KEYCLOAK_URL;
const keycloakClientId = process.env.REACT_APP_CLIENT_ID;

if (!keycloakUrl || !keycloakClientId) {
	throw new Error(
		"Missing required Keycloak environment variables. Please ensure that KEYCLOAK_URL, and REACT_APP_CLIENT_ID are set.",
	);
}

const createKeycloakInstance = (realm: string) => {
	if (typeof document !== "undefined") {
		return new Keycloak({
			url: keycloakUrl,
			realm: realm,
			clientId: keycloakClientId,
		});
	} else {
		return null;
	}
};

export default createKeycloakInstance;
